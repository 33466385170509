import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchUserFriendsRecipes } from '../../util/http';
import { useUser } from '../../store/UserContext';
import ErrorBlock from '../UI/ErrorBlock';
import LoadingIndicator from '../UI/LoadingIndicator';
import RecipeCard from '../Recipes/RecipeCard';
import Section from '../UI/Section';
import { Recipe, User } from '../../types';
import Tabs from '../UI/Tabs';

export default function UserFriendsRecipes({ user }: { user?: User }) {
  const params = useParams();
  const username = user ? user.username : params.username;
  const { user: loggedUser } = useUser();
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') || 'latest';

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['friends-recipes', username, { max: 99 }],
    queryFn: ({ signal }) => fetchUserFriendsRecipes({ username, signal, max: 99 }),
  });

  let content;

  if (isPending) {
    content = (
      <div className="my-12">
        <LoadingIndicator />
      </div>
    );
  }

  if (isError) {
    content = <ErrorBlock title="An error occurred" message={error?.message || 'Failed to fetch friends recipes.'} />;
  }

  // 🔹 Filter recipes based on the active tab
  let filteredRecipes: Recipe[] = data || [];
  if (activeTab === 'ai-creations') {
    filteredRecipes = filteredRecipes?.filter((recipe) => recipe.ai);
  }

  if (data) {
    content = filteredRecipes?.length ? (
      filteredRecipes?.map((recipe: Recipe) => <RecipeCard recipe={recipe} key={recipe.id} user={user} />)
    ) : (
      <p className="mt-12 text-sm text-gray-500">No recipes added yet.</p>
    );
  }
  // 🔹 Define tabs
  const tabs = [
    { label: 'Shared with Me', value: 'shared', count: data?.length },
    { label: 'AI Creations', value: 'ai-creations', count: filteredRecipes?.filter((r: any) => r.ai)?.length },
    // { label: 'Collections', value: 'collections', count: filteredCollections?.length },
  ];

  return (
    <div className="w-full m-auto">
      <Section
        title={
          loggedUser?.username === username
            ? 'Your friends recipes'
            : `${params.username} friends' recipes (${data?.length})`
        }
      >
        <Tabs tabs={tabs} defaultTab="shared" />

        <div className="mt-24 sm:mt-4 min-h-[calc(100dvh-11.5rem)]">
          <div className="flex flex-wrap justify-center">{content}</div>
        </div>
      </Section>
    </div>
  );
}
