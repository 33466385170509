import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { BookmarkIcon, LinkIcon, PencilIcon, PlusIcon, SparklesIcon } from '@heroicons/react/24/outline';

export default function AddNewButton() {
  const menuClasses = 'group flex gap-3 w-full items-center px-4 py-4 text-sm font-semibold';

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="inline-flex w-full justify-center items-center rounded-md px-4 py-3 text-sm font-semibold text-white bg-gray-700 hover:bg-gray-800 outline-none focus:ring-4 transition-all">
        <span>Create</span>
        <PlusIcon className="-mr-1 ml-2 size-5 text-gray-200 hover:text-gray-100" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-[1] left-0 mt-2 w-64 origin-top-left divide-y  rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none border border-gray-300">
          <Menu.Item>
            {({ active }) => (
              <NavLink
                to={`/recipes/new`}
                className={`${active ? 'bg-gray-100' : 'text-gray-800'} ${menuClasses} rounded-t-md`}
              >
                <PencilIcon className="size-5 shrink-0" />
                New Recipe
              </NavLink>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <NavLink
                to={`/recipes/ai-get-recipe-from-url`}
                className={`${active ? 'bg-gray-100' : 'text-gray-800'} ${menuClasses}`}
              >
                <LinkIcon className="size-5 shrink-0" />
                Generate from URL
              </NavLink>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <NavLink
                to={`/recipes/ai-generate-recipes`}
                className={`${active ? 'bg-gray-100' : 'text-gray-800'} ${menuClasses}`}
              >
                <SparklesIcon className="size-5 shrink-0" />
                Generate recipes with AI
              </NavLink>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <NavLink
                to={`/collections/new/`}
                className={`${active ? 'bg-gray-100' : 'text-gray-800'} ${menuClasses} rounded-b-md`}
              >
                <BookmarkIcon className="size-5 shrink-0" />
                Collection of Recipes
              </NavLink>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
