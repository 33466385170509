import React, { useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { fetchInviteCount, sendInvite } from '../../util/http';
import ContentBox from '../UI/ContentBox';
import Section from '../UI/Section';
import { useUser } from '../../store/UserContext';
import Button from '../UI/Button';
import { UsersIcon } from '@heroicons/react/24/outline';
import LoadingIndicator from '../UI/LoadingIndicator';
import ErrorBlock from '../UI/ErrorBlock';
import SuccessBlock from '../UI/SuccessBlock';

export default function InviteFriends() {
  const { user } = useUser();
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [inviteSent, setInviteSent] = useState<boolean>(false);

  const labelClasses = 'block text-gray-800 font-bold mb-4 cursor-pointer';
  const inputClasses =
    'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-4 transition-all';

  const { data, isPending, refetch } = useQuery({
    queryKey: ['inviteCount', user?.id],
    queryFn: ({ signal }) => fetchInviteCount({ uid: user?.id, signal }),
  });

  const inviteCount = data ?? 0;

  const { mutate: mutateInvite, isPending: isPendingInvite } = useMutation({
    mutationFn: async (inviteData: { email: string }) => sendInvite({ uid: user?.id, email: inviteData.email }),

    onSuccess: () => {
      setError(null);
      setInviteSent(true);

      setTimeout(() => {
        setInviteSent(false);
        setEmail('');
      }, 9000);
      refetch();
    },

    onError: (error: any) => {
      setError(error?.message || 'An unknown error occurred.');
    },
  });

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailPattern.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    setError(null);
    mutateInvite({ email });
  }

  return (
    <Section isNarrow title="Invite Your Friends" hasIcons={false} wrapperClasses="max-w-lg m-auto">
      <ContentBox className="flex flex-col gap-8 overflow-clip">
        <h2
          className={`text-bold text-center tracking-wide rounded ${
            inviteCount >= 3 ? 'bg-rose-100 ' : 'bg-gray-100 '
          } p-12 px-8 text-gray-900`}
        >
          {isPending || isPendingInvite ? (
            <div className="min-h-40 sm:min-h-28 inline-flex items-center justify-center">
              <LoadingIndicator />
            </div>
          ) : (
            <div className="min-h-40 sm:min-h-28 inline-flex flex-col sm:flex-row items-center justify-center gap-4">
              <UsersIcon className="size-6" />
              <span>
                You have <strong className="text-bold">{3 - inviteCount}</strong> invite
                {inviteCount === 2 ? '' : 's'} left.
              </span>
            </div>
          )}
        </h2>

        <form onSubmit={handleSubmit} className="flex flex-col gap-8">
          <div>
            <label htmlFor="email" className={labelClasses}>
              Email address
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Friend's email"
              className={inputClasses}
              autoComplete="off"
              disabled={inviteSent}
            />
          </div>
          <Button type="submit" isPrimary disabled={isPending || inviteCount >= 3 || inviteSent}>
            Send Invite
          </Button>
        </form>
        {error && <ErrorBlock title="An error occurred" message={error} onClose={() => setError(null)} />}
        {inviteSent && (
          <SuccessBlock
            title="Invite to friend sent"
            message={
              <span>
                An invite for <strong className="break-all">{email}</strong> has been successfully sent!
              </span>
            }
            onClose={() => setInviteSent(false)}
          />
        )}
      </ContentBox>
    </Section>
  );
}
