import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import ScrollToTop from '../components/UI/ScrollToTop';

export default function RootLayout() {
  return (
    <>
      <Header />
      <ScrollToTop />
      <main className="pt-[5.5rem] xl:pt-4">
        <Outlet />
      </main>
    </>
  );
}
