import React, { useState, useRef, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  CheckCircleIcon,
  ChevronLeftIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';

import { useUser } from '../../store/UserContext';
import {
  AddToUserCollections,
  createNewCollection,
  fetchUserCollections,
  queryClient,
} from '../../util/http';

import Modal from '../UI/Modal';
import Button from '../UI/Button';
import ErrorBlock from '../UI/ErrorBlock';
import LoadingIndicator from '../UI/LoadingIndicator';

export default function SelectCollectionModal({
  recipeId,
  recipeTitle,
  onClose,
}: {
  recipeId: string;
  recipeTitle: string;
  onClose: () => void;
}) {
  const { user } = useUser();
  const [selectedCollection, setSelectedCollection] = useState({
    id: '',
    name: '',
  });
  const [showAddNew, setShowAddNew] = useState(false);
  const [newCollectionDescription, setNewCollectionDescription] = useState('');
  const newCollectionInput = useRef(null);
  const newCollectionDescriptionInput = useRef(null);

  const collectionItemClasses =
    'w-full p-4 bg-gray-50 rounded-md flex items-center justify-between gap-2 text-left transition outline-none focus:ring-4';

  const inputClasses =
    'shadow appearance-none border rounded-md w-full p-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline transition outline-none focus:ring-4';

  const { data: collections, isPending: isPendingCollections } = useQuery({
    queryKey: ['user-collections', user.uid],
    queryFn: ({ signal }) => fetchUserCollections({ signal, uid: user.uid }),
  });

  const { mutate, isPending, isError, error, isSuccess } = useMutation({
    mutationFn: AddToUserCollections,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['collections'] });
      queryClient.invalidateQueries({
        queryKey: ['user-collections', user.uid],
      });

      setTimeout(() => {
        onClose();
      }, 5000);
    },
  });

  function handleCreateCollection(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const collectionData = {
      uid: user.uid,
      collection: selectedCollection.name,
      description: newCollectionDescription,
      recipeId: recipeId,
    };

    createNewCollection(collectionData)
      .then((newCollection) => {
        setSelectedCollection({
          id: newCollection.id,
          name: newCollection.name,
        });

        mutate([user.username, newCollection.id, recipeId]);
      })
      .catch((error) => {
        <ErrorBlock
          title='Failed to create new collection'
          message={
            error?.message ||
            'Failed to create a new collection. Please try again later.'
          }
        />;
      });
  }

  function handleAddToCollection(recipeId: string) {
    mutate([user.username, selectedCollection.id, recipeId]);
  }

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  let content;

  const addNewCollection = (
    <form
      className='flex flex-col gap-2 md:flex-row'
      onSubmit={(event) => handleCreateCollection(event)}
    >
      <div className='flex flex-col gap-2 w-full'>
        <label className='sr-only' htmlFor='collection-name'>
          New collection name
        </label>
        <input
          type='text'
          id='collection-name'
          name='collection-name'
          className={inputClasses}
          maxLength={40}
          placeholder='New collection name'
          required
          autoFocus
          ref={newCollectionInput}
          onChange={() =>
            // @ts-ignore
            setSelectedCollection({ name: newCollectionInput.current.value })
          }
        />
        <label className='sr-only' htmlFor='collection-description'>
          New collection description
        </label>
        <textarea
          className={inputClasses}
          id='collection-description'
          name='collection-description'
          placeholder='Add description'
          rows={4}
          ref={newCollectionDescriptionInput}
          onChange={() =>
            setNewCollectionDescription(
              // @ts-ignore
              newCollectionDescriptionInput.current.value
            )
          }
        />
        <div className='flex gap-2 mt-1'>
          <Button
            onClick={() => setShowAddNew(false)}
            title='Go back to collection list'
          >
            <ChevronLeftIcon className='h-6 w-6 text-gray-700' />
          </Button>
          {/* @ts-ignore */}
          <Button onClick={handleCreateCollection} isPrimary className='w-full'>
            Create
          </Button>
        </div>
      </div>
    </form>
  );

  content = showAddNew ? (
    addNewCollection
  ) : (
    <>
      <ul className='flex flex-col gap-2'>
        <li>
          <button
            onClick={() => setShowAddNew(true)}
            className={collectionItemClasses}
          >
            <span>Create a new collection...</span>
            <PlusCircleIcon className='w-6 h-6 text-gray-700' />
          </button>
        </li>
        {isPendingCollections && (
          <div className='p-4 flex gap-4 items-center bg-gray-50 rounded-md'>
            <LoadingIndicator />
            <p className='w-full'>Loading collections...</p>
          </div>
        )}
        {collections?.map((collection: any) => (
          <li key={collection.name}>
            <button
              onClick={() =>
                setSelectedCollection({
                  id: collection.id,
                  name: collection.name,
                })
              }
              className={
                collection.id === selectedCollection.id
                  ? `border-gray-700 bg-white transition-all shadow-lg font-bold ${collectionItemClasses}`
                  : collectionItemClasses
              }
            >
              <span className='text-ellipsis overflow-hidden line-clamp-2'>
                {collection.name}
              </span>
              {collection.id === selectedCollection.id ? (
                <CheckCircleIcon className='w-6 h-6 text-gray-700 shrink-0' />
              ) : null}
            </button>
          </li>
        ))}
      </ul>
      <div className='flex gap-4 flex-nowrap'>
        <Button
          onClick={() => onClose()}
          className='sticky bottom-0 w-auto mt-4'
        >
          Cancel
        </Button>
        <Button
          isPrimary
          onClick={() => handleAddToCollection(recipeId)}
          className='sticky bottom-0 w-full mt-4'
          disabled={selectedCollection.id === ''}
        >
          Save to Collection
        </Button>
      </div>
    </>
  );

  return (
    <Modal>
      <section className='relative text-center text-pretty'>
        {!isSuccess && (
          <h2 className='text-lg pb-4'>
            Where do you want to save
            <br />"<span className='font-bold'>{recipeTitle}</span>"?
          </h2>
        )}
        {isPending && (
          <div className='p-4 flex gap-4 items-center bg-gray-50 rounded-md'>
            <LoadingIndicator />
            <p className='w-full'>Adding to collection...</p>
          </div>
        )}
        {!isPending && !isSuccess && <>{content}</>}
        {isSuccess && (
          <div className='flex flex-col gap-4 p-4 bg-lime-50 rounded-md justify-center -m-8'>
            <CheckCircleIcon className='mt-4 w-16 h-16 text-lime-700 m-auto' />
            <p className='flex flex-col gap-4 justify-center items-center mb-2'>
              <span>Recipe added to collection:</span>
              <Button to={`/collections/${selectedCollection.id}`}>
                {selectedCollection.name}
              </Button>
            </p>
          </div>
        )}
        {isError && (
          <ErrorBlock
            title='Failed to add to collection'
            message={
              error?.message ||
              'Please check your selection and try again later.'
            }
          />
        )}
      </section>
    </Modal>
  );
}
