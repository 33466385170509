export default function Badge({ type }: { type: 'ai' | 'draft' | 'new' | 'private' }) {
  let badgeColor;

  switch (type) {
    case 'ai':
      badgeColor = 'bg-fuchsia-100 text-fuchsia-600';
      break;
    default:
      badgeColor = 'bg-gray-200 text-gray-600';
      break;
  }

  return (
    <span className={`px-2 sm:px-3 text-xs ${badgeColor} p-1 font-bold rounded-full shrink-0`}>
      {
        {
          ai: 'AI',
          draft: 'Draft',
          new: 'New',
          private: 'Private',
        }[type]
      }
    </span>
  );
}
