import React from 'react';
import { HeartIcon } from '@heroicons/react/24/outline';
import { HeartIcon as HeartIconSolid } from '@heroicons/react/24/solid';
import { queryClient } from '../../util/http';
import { supabase } from '../../util/supabaseClient';
import { useMutation, useQuery } from '@tanstack/react-query';

interface AddToFavoritesProps {
  isIcon?: boolean;
  isButton?: boolean;
  userUid: string;
  recipeId: string;
}

const AddToFavoritesButton: React.FC<AddToFavoritesProps> = ({ isIcon = false, isButton = false, userUid, recipeId }) => {
  // Fetch all favorites once (move this to a parent component if possible)
  const { data: favorites = [], isLoading } = useQuery({
    queryKey: ['favorites'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('favorites')
        .select('recipe_id')
        .eq('user_uid', userUid);

      if (error) throw error;
      return data || []; // Ensure we always return an array
    },
  });

  // Check if the recipe is favorited locally
  const isFavorited = favorites.some((fav) => fav.recipe_id === recipeId);

  // Mutations
  const addToFavorites = useMutation({
    mutationFn: async () => {
      const { error } = await supabase.from('favorites').upsert([{ user_uid: userUid, recipe_id: recipeId }]);
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['favorites'] }); // Only invalidate once
    },
  });

  const removeFromFavorites = useMutation({
    mutationFn: async () => {
      const { error } = await supabase.from('favorites').delete().match({ user_uid: userUid, recipe_id: recipeId });
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['favorites'] });
    },
  });

  return (
    <>
      {isIcon && (
        <button
          onClick={() => (isFavorited ? removeFromFavorites.mutate() : addToFavorites.mutate())}
          className={`p-2 ${isFavorited ? 'text-rose-500' : 'text-gray-500'} transition outline-none focus:ring-4 rounded-full`}
          disabled={isLoading}
        >
          {isFavorited ? <HeartIconSolid className="h-6 w-6" /> : <HeartIcon className="h-6 w-6" />}
        </button>
      )}

      {isButton && (
        <button
          onClick={(event) => {
            event.preventDefault();
            return isFavorited ? removeFromFavorites.mutate() : addToFavorites.mutate()}
        }
          className={`-m-4 p-4 w-[calc(100%+2rem)] flex gap-3`}
          disabled={isLoading}
        >
          <span className={isFavorited ? 'text-rose-500' : 'text-gray-800'}>
            {isFavorited ? <HeartIconSolid className="size-5 shrink-0" /> : <HeartIcon className="size-5 shrink-0" />}
          </span>
          {isFavorited ? 'Added to Favorites' : 'Add to Favorites'}
        </button>
      )}
    </>
  );
};

export default AddToFavoritesButton;
