import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon, BookmarkIcon, PhotoIcon } from '@heroicons/react/24/outline';
import Badge from '../UI/Badge';
import formatDate from '../../util/date';
import { Recipe } from '../../types';

interface RecipeArticleProps {
  recipe: Recipe;
}

export default function RecipeArticle({ recipe }: RecipeArticleProps): JSX.Element {
  const createdAt = formatDate(recipe.created_at);

  return (
    <div className="bg-white shadow-xl rounded-xl m-4">
      {recipe?.image !== null && recipe?.image !== undefined ? (
        <img
          src={recipe.image}
          alt={recipe.title}
          className="bg-skeleton rounded-tl-xl rounded-tr-xl min-h-[28rem] sm:min-h-[36rem] max-h-[60rem] print:max-h-64 w-full transition"
          loading="lazy"
        />
      ) : (
        <div className="bg-gray-100 opacity-90 object-cover rounded-md w-full h-[32rem] flex flex-col gap-4 justify-center items-center">
          <PhotoIcon className="size-12 text-gray-300" />
          <p className="text-sm text-gray-400">No image added yet</p>
        </div>
      )}
      <div className="flex p-10 sm:p-12 sm:pb-8 print:pb-6 gap-12 flex-col sm:flex-row print:flex-row relative text-gray-800">
        <div className="m-auto flex items-cente justify-end gap-2 top-10 right-10 sm:top-12 sm:right-12 absolute">
          {recipe.draft && <Badge type="draft" />}
          {recipe.ai && <Badge type="ai" />}
        </div>
        <div className="w-full sm:w-[24rem] print:w-[24rem]">
          <h2 className="text-xl font-bold text-gray-600">Ingredients</h2>
          {recipe.ingredients?.length === 0 && <p className="py-4 text-pretty">No ingredients added yet.</p>}
          <ul className="py-4 text-pretty">
            {recipe.ingredients?.map((ingredient) => {
              return (
                <li key={ingredient} className="py-1">
                  {ingredient}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="w-full">
          <h2 className="text-xl font-bold text-gray-600">Steps</h2>
          {recipe.steps?.length === 0 && <p className="py-4 text-pretty">No steps added yet.</p>}
          <ol className="p-4 text-pretty list-decimal marker:text-gray-400">
            {recipe.steps?.map((step) => {
              return (
                <li key={step} className="py-4 sm:py-2 print:py-2 text-pretty">
                  {step}
                </li>
              );
            })}
          </ol>
        </div>
      </div>

      {recipe.source && (
        <div className="px-12 flex flex-col sm:flex-row print:flex-row gap-12 items-center justify-between text-slate-700 bg-slate-50">
          <h2 className="w-full sm:w-[24rem] print:w-[24rem] font-bold text-gray-600 text-sm">Recipe Source</h2>
          <Link
            className="w-full py-4 text-pretty flex items-center gap-2 text-sm hover:underline"
            to={recipe.source}
            title="Open recipe source website"
            target="_blank"
          >
            <span className="text-ellipsis overflow-hidden line-clamp-1">{recipe.source}</span>
            <ArrowTopRightOnSquareIcon className="size-4 shrink-0" />
          </Link>
        </div>
      )}
      <div className="flex flex-col sm:flex-row print:flex-row justify-center bg-gray-50 border-2 border-white rounded-b-xl">
        {recipe.author && (
          <h2 className="p-6 sm:px-8 flex flex-col sm:flex-row print:flex-row gap-4 print:gap-4 items-center justify-centert">
            <NavLink
              to={`/users/${recipe.author.username}/`}
              className="flex gap-3 pe-6 items-center rounded-full border border-gray-300 bg-white shadow hover:shadow-lg transition outline-none focus:ring-4"
            >
              <img
                src={recipe.author?.image}
                alt={`${recipe.author?.name}'s avatar`}
                className="size-12 rounded-full border-2 border-white shrink-0"
                loading="lazy"
              />
              <span className="font-bold block shrink-0">{recipe.author.name}</span>
            </NavLink>
            <span className="text-center text-balance">
              added this recipe on <time>{createdAt}</time>
              {recipe.category && (
                <span>
                  {' '}in{' '}
                  <Link to={`/categories/?tab=${recipe.category.slug}`} className="font-bold transition outline-none focus:ring-4 rounded">
                    {recipe.category.name}
                  </Link>
                </span>
              )}
            </span>
          </h2>
        )}
        {recipe.collections_count! > 0 && (
          <span
            className="print:hidden relative -mt-2 sm:my-6 sm:mr-8 print:my-6 print:mr-8 px-4 py-2 mx-auto h-auto flex items-center justify-center gap-1 text-gray-700 bg-white borde rounded-lg group cursor-help"
            aria-label={`This recipe has been saved in ${recipe.collections_count} ${
              recipe.collections_count === 1 ? 'collection' : 'collections'
            }.`}
          >
            <BookmarkIcon className="size-4" />
            {recipe.collections_count}
            <span
              role="tooltip"
              className="absolute top-full px-3 py-2 min-w-32 bg-gray-800 text-white text-xs text-center text-balance rounded shadow-lg opacity-0 scale-90 group-hover:opacity-100 group-hover:scale-100 transition-all"
            >
              {`This recipe has been saved in ${recipe.collections_count} ${
                recipe.collections_count === 1 ? 'collection' : 'collections'
              }.`}
            </span>
          </span>
        )}
      </div>
    </div>
  );
}
