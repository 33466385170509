import { useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Recipe } from '../../types';
import { fetchDailyAIRecipes } from '../../util/http';
import ErrorBlock from '../UI/ErrorBlock';
import RecipesSkeleton from '../UI/RecipesSkeleton';
import Section from '../UI/Section';
import Tabs from '../UI/Tabs';
import RecipeCard from './RecipeCard';

export default function AIDailyRecipes() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') || 'latest-recipes';

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['ai-daily-recipes', { max: 99 }],
    queryFn: ({ signal }) => fetchDailyAIRecipes({ signal, max: 99 }),
  });

  let content: JSX.Element | null = null;

  // 🔹 Filter recipes based on the active tab
  let filteredRecipes = data || [];
  // if (activeTab === 'ai-generated') {
  //   filteredRecipes = filteredRecipes.filter((recipe: any) => recipe.ai);
  // } else if (activeTab === 'shared') {
  //   filteredRecipes = filteredRecipes.filter((recipe: any) => recipe.isSharedWithMe);
  // } else if (activeTab === 'collections') {
  //   filteredCollections = collectionsData;
  // }

  // 🔹 Define tabs
  const tabs = [{ label: 'Latest AI Recipes', value: 'latest-ai-recipes', count: data?.length }];

  if (isPending) {
    content = <RecipesSkeleton />;
  }

  if (isError) {
    content = <ErrorBlock title="An error occurred" message={error?.message || 'Failed to fetch daily AI recipes.'} />;
  }

  // Recipes
  if (data && filteredRecipes) {
    filteredRecipes.sort((a: any, b: any) => b.createdAt?._seconds - a.createdAt?._seconds);

    content = (
      <div className="flex flex-wrap justify-center text-gray-800">
        {filteredRecipes.map((recipe: Recipe) => {
          const user = recipe.user;

          return <RecipeCard recipe={recipe} key={recipe.id} path="recipes/ai-daily-recipes" />;
        })}
      </div>
    );
  }

  return (
    <>
      {/* @ts-ignore */}
      {/*<Helmet>*/}
      {/*  <title>Bokkah – Recipes for all</title>*/}
      {/*  <meta property='og:title' content='Bokkah – Recipes for all' />*/}
      {/*  <meta*/}
      {/*    property='og:description'*/}
      {/*    content='Share your recipes with other people, get inspired and new ideas to cook.'*/}
      {/*  />*/}
      {/*</Helmet>*/}
      <Section title="Daily AI Recipes" hasRecipes hasIcons={false}>
        <Tabs tabs={tabs} defaultTab="latest-recipes" />
        <div className="mt-24 sm:mt-4 min-h-[calc(100dvh-11.5rem)]">{content}</div>
      </Section>
    </>
  );
}
