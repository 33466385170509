import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './util/http';

import './index.css';
import RootLayout from './pages/Root';

// Error
import Error from './pages/Error';

// Recipes
import Recipes from './components/Recipes/Recipes';
import NewRecipe from './components/Recipes/NewRecipe';
import RecipeDetail from './components/Recipes/RecipeDetail';
import EditRecipe, { loader as editRecipeLoader } from './components/Recipes/EditRecipe';
import SearchRecipes from './components/Recipes/SearchRecipes';
import Categories from './components/Categories/Categories';
import CategoryDetail from './components/Categories/CategoryDetail';
// AI – Recipes
import AIGenerateRecipes from './components/Recipes/AIGenerateRecipes';
import AINewRecipeFromUrl from './components/Recipes/AINewRecipeFromUrl';
import AIDailyRecipes from './components/Recipes/AIDailyRecipes';

// Users
import { UserProvider } from './store/UserContext';
import NewUser from './components/Users/NewUser';
import Users from './components/Users/Users';
import UserDetail from './components/Users/UserDetail';
import EditUser, { loader as editUserLoader, action as editUserAction } from './components/Users/EditUser';
import UserFavorties from './components/Users/UserFavorites';
import UserFriends from './components/Users/UserFriends';
import UserFriendsRecipes from './components/Users/UserFriendsRecipes';
import UserFriendsCollections from './components/Users/UserFriendsCollections';

// Login
// import SignUp from './pages/SignUp';
import SignupInvite from './pages/SignUpInvite';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

// Dashboard
import Notifications from './components/Dashboard/Notifications';
import Dashboard from './components/Dashboard/Dashboard';
import UserCollections from './components/Users/UserCollections';

// Collections
import Collections from './components/Collections/Collections';
import CollectionDetail from './components/Collections/CollectionDetail';
import NewCollection from './components/Collections/NewCollection';
import EditCollection, { loader as editCollectionLoader } from './components/Collections/EditCollection';
import { NotificationsProvider } from './store/NotificationsContext';
import UserRecipes from './components/Users/UserRecipes';

// Others
import Podcast from './pages/Podcast';
import InviteFriends from './components/Users/InviteFriends';
import AIDailyRecipeDetail from './components/Recipes/AIDailyRecipeDetail';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <Error error="An error occured. Pleace try again later." />,
    children: [
      {
        index: true,
        element: <SearchRecipes />,
      },
      {
        path: 'recipes',
        children: [
          { index: true, element: <Recipes /> },
          {
            path: ':recipeId',
            id: 'recipe-detail',
            children: [
              { index: true, element: <RecipeDetail /> },
              {
                path: 'edit',
                element: <EditRecipe />,
                loader: editRecipeLoader,
              },
            ],
          },
          { path: 'new', element: <NewRecipe /> },
          { path: 'ai-get-recipe-from-url', element: <AINewRecipeFromUrl /> },
          { path: 'ai-generate-recipes', element: <AIGenerateRecipes /> },
          {
            path: 'ai-daily-recipes',
            children: [
              { index: true, element: <AIDailyRecipes /> },
              {
                path: ':recipeId',
                id: 'ai-recipe-detail',
                element: <AIDailyRecipeDetail />,
              },
            ],
          },
        ],
      },
      {
        path: 'categories',
        children: [
          { index: true, element: <Categories /> },
          {
            path: ':slug',
            id: 'category-detail',
            element: <CategoryDetail />,
          },
        ],
      },
      {
        path: 'collections',
        children: [
          { index: true, element: <Collections /> },
          {
            path: ':collectionId',
            id: 'collection-detail',
            children: [
              { index: true, element: <CollectionDetail /> },
              {
                path: 'edit',
                element: <EditCollection />,
                loader: editCollectionLoader,
              },
            ],
          },
          { path: 'new', element: <NewCollection /> },
        ],
      },
      {
        path: 'users',
        children: [
          { index: true, element: <Users /> },
          {
            path: ':username',
            id: 'user-detail',
            children: [
              { index: true, element: <UserDetail /> },
              {
                path: 'edit',
                element: <EditUser />,
                loader: editUserLoader,
                action: editUserAction,
              },
              { path: 'favorites', element: <UserFavorties /> },
              { path: 'recipes', element: <UserRecipes /> },
              { path: 'collections', element: <UserCollections /> },
              {
                path: 'friends',
                children: [
                  { path: '', element: <UserFriends /> },
                  { path: 'recipes', element: <UserFriendsRecipes /> },
                  { path: 'collections', element: <UserFriendsCollections /> },
                ],
              },
            ],
          },
          { path: 'new', element: <NewUser /> },
        ],
      },
      {
        path: 'dashboard',
        children: [
          { path: 'news-feed', element: <Dashboard /> },
          { path: 'notifications', element: <Notifications /> },
        ],
      },
      // { path: 'sign-up', element: <SignUp /> },
      { path: 'sign-up', element: <SignupInvite /> },
      { path: 'login', element: <Login /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: 'invite-friends', element: <InviteFriends /> },
      { path: 'podcast', element: <Podcast /> },
    ],
  },
]);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <NotificationsProvider>
          <RouterProvider router={router} />
        </NotificationsProvider>
      </UserProvider>
    </QueryClientProvider>
  );
}

export default App;
