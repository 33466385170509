import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { fetchCategories, fetchRecipes } from '../../util/http';

import Section from '../UI/Section';
import LoadingIndicator from '../UI/LoadingIndicator';
import ErrorBlock from '../UI/ErrorBlock';
import Tabs from '../UI/Tabs';
import { Recipe } from '../../types';
import RecipeCard from '../Recipes/RecipeCard';

interface Category {
  id: number;
  name: string;
  slug: string;
  count: number;
  recipes: Recipe[];
}

export default function Categories() {
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') || 'breakfast';

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['categories'],
    queryFn: ({ signal }) => fetchCategories({ signal }),
  });

  const {
    data: recipesData,
    isPending: isPendingRecipes,
    isError: isErrorRecipes,
    error: errorRecipes,
  } = useQuery({
    queryKey: ['recipes', { category: activeTab, max: 99 }],
    queryFn: ({ signal }) =>
      fetchRecipes({
        signal,
        searchTerm: null,
        category: activeTab,
        max: 99,
      }),
  });

  let content: JSX.Element | null = null;
  let filteredRecipes = recipesData || [];

  // 🔹 Define tabs
  const tabs =
    data
      ?.filter((c: Category) => c.count !== 0)
      ?.map((category: Category) => {
        return {
          label: category?.name,
          value: category?.slug,
          count: category?.count,
        };
      }) || [];

  if (isPending) {
    content = <LoadingIndicator />;
  }

  if (isError) {
    content = <ErrorBlock title="An error occurred" message={error?.message || 'Failed to fetch categories.'} />;
  }

  if (recipesData) {
    content = (
      <div className="flex flex-wrap justify-center text-gray-800">
        {filteredRecipes?.map((recipe: Recipe) => {
          const user = recipe.user;

          return <RecipeCard user={user} recipe={recipe} key={recipe.id} />;
        })}
      </div>
    );
  }

  return (
    <>
      {data && (
        <Helmet>
          <title>{`All categories | Bokkah`}</title>
          <meta property="og:title" content={`All categories | Bokkah`} />
          <meta property="og:image" content={`/og-image.jpg`} />
        </Helmet>
      )}
      <Section title={' Categories'} hasIcons={false} wrapperClasses="w-full m-auto">
        <Tabs tabs={tabs} defaultTab="breakfast"/>
        <div className="mt-24 sm:mt-4 min-h-[calc(100dvh-11.5rem)]">
          <div className="flex flex-wrap justify-center">{content}</div>
        </div>
      </Section>
    </>
  );
}
