import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { PencilIcon, TrashIcon, UserPlusIcon } from '@heroicons/react/24/outline';

export default function EditCollectionButton({
  collectionId,
  setIsDeleting,
  setIsSharing,
  showDeleteButton = false,
}: {
  collectionId: string;
  setIsDeleting?: (isDeleting: boolean) => void;
  setIsSharing?: (isSharing: boolean) => void;
  showDeleteButton?: boolean;
}) {
  const menuClasses = 'group flex gap-3 w-full items-center px-4 py-4 text-sm';

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="inline-flex w-full justify-center rounded-md px-4 py-3 text-sm font-medium text-white bg-gray-700 hover:bg-gray-800 outline-none focus:ring-4 transition-all">
        <span>Edit Collection</span>
        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-200 hover:text-gray-100" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-[1] left-0 mt-2 w-64 origin-top-left divide-y  rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none border border-gray-300">
            <Menu.Item>
              {({ active }) => (
                <NavLink
                  to={`/collections/${collectionId}/edit`}
                  className={`${active ? 'bg-gray-100' : 'text-gray-800'} ${menuClasses} rounded-t-md`}
                >
                  <PencilIcon className="size-4" />
                  Collection details
                </NavLink>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => setIsSharing && setIsSharing(true)}
                  className={`${active ? 'bg-gray-100' : 'text-gray-800'} ${menuClasses}`}
                >
                  <UserPlusIcon className="size-4" />
                  Share with others
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => setIsDeleting && setIsDeleting(true)}
                  className={`${active ? 'bg-red-50' : 'text-gray-900'} ${menuClasses} rounded-b-md`}
                >
                  <TrashIcon className="size-4" />
                  Delete
                </button>
              )}
            </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
