import React, {Fragment, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {Menu, Transition} from '@headlessui/react';
import {
  BookmarkIcon,
  EllipsisVerticalIcon,
  HeartIcon,
  PrinterIcon,
  ShareIcon, XMarkIcon
} from '@heroicons/react/24/outline';
import SelectCollectionModal from "../Collections/SelectCollectionModal";
import Modal from "./Modal";
import H2 from "./H2";
import Share from "./Share";
import AddToFavoritesButton from "./AddToFavoritesButton";
import {useUser} from "../../store/UserContext";

type RecipeOptionsButtonProps = {
  recipeId: string;
  recipeTitle: string;
  recipeImage: string;
}

export default function RecipeOptionsButton({ recipeId, recipeTitle, recipeImage }: RecipeOptionsButtonProps ) {
  const [showCollections, setShowCollections] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const { user } = useUser();

  const menuClasses = 'group flex gap-3 w-full items-center p-4 text-sm font-semibold';

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="w-14 h-14 text-gray-400 rounded-full p-4 shadow hover:shadow-xl hover:bg-white hover:text-gray-700 transition outline-none focus:ring-4">
          <span className="sr-only">Recipe Options Menu</span>
          <EllipsisVerticalIcon className="size-6" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-[1] right-0 mt-2 w-64 origin-top-right divide-y rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none border border-gray-300">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => setShowCollections(true)}
                  className={`${active ? 'bg-gray-100' : 'text-gray-800'} ${menuClasses} rounded-t-md`}
                >
                  <BookmarkIcon className="size-5 shrink-0" />
                  Save to Collection
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <span
                  className={`${active ? 'bg-gray-100' : 'text-gray-800'} ${menuClasses} p-0`}
                >
                  <AddToFavoritesButton isButton={true} recipeId={recipeId || ''} userUid={user?.uid} />
                </span>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => setIsShareModalOpen(true)}
                  className={`${active ? 'bg-gray-100' : 'text-gray-800'} ${menuClasses}`}
                >
                  <ShareIcon className="size-5 shrink-0" />
                  Share
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => window.print()}
                  className={`${active ? 'bg-gray-100' : 'text-gray-800'} ${menuClasses} rounded-b-md`}
                >
                  <PrinterIcon className="size-5 shrink-0" />
                  Print
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>

      {showCollections && (
        <SelectCollectionModal
          recipeId={recipeId}
          recipeTitle={recipeTitle}
          onClose={() => setShowCollections(false)}
        />
      )}

      {isShareModalOpen && (
        <Modal onClose={() => setIsShareModalOpen(false)}>
          <button
            onClick={() => setIsShareModalOpen(false)}
            className="p-2 -m-2 rounded-lg hover:bg-white absolute right-4 top-4 focus:outline-none focus:shadow-outline focus:ring-4 ring-gray-300 transition-all"
          >
            <XMarkIcon className="size-4 text-gray-600" />
          </button>
          <H2 title="Share" className="text-center mb-4" />
          {/*<p className="mt-4 mb-8 tracking-wide text-center">{fullShareText}</p>*/}
          <Share url={`https://bokkah.com/recipes/${recipeId}`} title={recipeTitle} image={recipeImage} />
        </Modal>
      )}

    </>
  );
}
