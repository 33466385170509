import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UserForm from './UserForm';
import ErrorBlock from '../UI/ErrorBlock';
import { createNewUser, fetchUserByUid, queryClient } from '../../util/http';
import Button from '../UI/Button';
import { uploadImage } from '../../util/image';
import { User } from '../../types';
import { useUser } from '../../store/UserContext';
import { supabase } from '../../util/supabaseClient';

export default function NewUser() {
  const navigate = useNavigate();
  const { signIn: setUser } = useUser();
  const [searchParams] = useSearchParams();
  const [imageFile, setImageFile] = useState<File | null>(null);
  const friendUid = searchParams.get('friend_uid');

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationFn: createNewUser,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['users'] });

      if (friendUid) {
        const { data, error: friendsError } = await supabase.from('friends').insert([
          { user_uid: searchParams.get('uid'), friend_uid: friendUid },
          { user_uid: friendUid, friend_uid: searchParams.get('uid') }, // Mutual friendship
        ]);

        if (friendsError) {
          throw new Error(friendsError.message || 'Failed to add users as friends');
        }

        await supabase.from('notifications').insert({
          type: 'INVITE_TO_SIGN_UP_ACCEPTED',
          status: 'PENDING',
          from_user: searchParams.get('uid'),
          notifier: friendUid,
        });
      }
    },
  });

  function handleImageChange(file: File | null) {
    setImageFile(file);
  }

  async function handleSubmit(formData: User) {
    try {
      let imageUrl = null;

      if (imageFile) {
        imageUrl = await uploadImage(imageFile, formData.username);
        formData.image = imageUrl;
      }

      await mutateAsync({ user: formData });

      const userData = await fetchUserByUid({
        uid: searchParams.get('uid'),
        signal: null,
      });

      if (!userData) {
        throw new Error("User data couldn't be retrieved after profile creation.");
      }

      setUser(userData.user);

      navigate(`/users/${formData.username}`);
    } catch (error) {
      console.error('Error handling profile creation:', error);
    }
  }

  const newUser = {
    uid: searchParams.get('uid'),
    username: searchParams.get('username'),
    name: searchParams.get('name'),
    email: searchParams.get('email') ? decodeURIComponent(searchParams.get('email') ?? '').replace(/ /g, '+') : '',
    image: searchParams.get('image'),
  };

  let title = searchParams.get('title');

  return (
    <section className="max-w-3xl m-auto">
      <h1 className="text-center text-3xl font-bold p-8 text-gray-700">{title ? title : 'Create Your Profile'}</h1>

      <UserForm onSubmit={handleSubmit} newUser={newUser} onImageChange={handleImageChange}>
        {isPending && 'Submitting...'}
        {!isPending && (
          <div className="flex gap-4 justify-start">
            <Button type="submit" isPrimary>
              Create Profile
            </Button>
          </div>
        )}
      </UserForm>

      {isError && (
        <ErrorBlock
          title="Failed to create user"
          message={error?.message || 'Failed to create user. Please check your inputs and try again later.'}
        />
      )}
    </section>
  );
}
