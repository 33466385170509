import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { deleteRecipe, fetchRecipe, queryClient } from '../../util/http';
import { Helmet } from 'react-helmet';

import { useUser } from '../../store/UserContext';
import formatDate from '../../util/date';
import Modal from '../UI/Modal';
import ErrorBlock from '../UI/ErrorBlock';
import Section from '../UI/Section';
import Button from '../UI/Button';
import H2 from '../UI/H2';
import LoadingIndicator from '../UI/LoadingIndicator';
import Share from '../UI/Share';
import { PencilIcon, PrinterIcon, ShareIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Recipe } from '../../types';
import RecipeArticle from './RecipeArticle';

export default function RecipeDetail() {
  const params = useParams<{ recipeId: string }>();
  const navigate = useNavigate();
  const { user } = useUser();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const { data, isPending, isError, error } = useQuery<Recipe>({
    queryKey: ['recipes', params.recipeId],
    queryFn: ({ signal }) => fetchRecipe({ id: params.recipeId, signal }),
  });

  const {
    mutate,
    isPending: isPendingDeletion,
    isError: isErrorDeleting,
    error: deleteError,
  } = useMutation({
    mutationFn: deleteRecipe,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['recipes'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['users', { uid: user.uid }] });
      queryClient.invalidateQueries({ queryKey: ['collections'] });
      navigate(`/users/${user.username}`);
    },
  });

  function handleStartDelete() {
    setIsDeleting(true);
  }

  function handleStopDelete() {
    setIsDeleting(false);
  }

  function handleDelete() {
    mutate({ id: params.recipeId });
  }

  let content;

  if (isPending) {
    content = (
      <div className="mt-4">
        <LoadingIndicator />
      </div>
    );
  }

  if (isError) {
    content = <ErrorBlock title="An error occurred" message={error?.message || 'Failed to fetch recipe.'} />;
  }

  if (data) {
    const recipe: Recipe = data;
    const isSameUser = user?.uid === recipe.author?.uid;

    // Share
    const websiteUrl = `https://bokkah.com/recipes/${data.id}`;
    const shareText = `🍽️ Check out this amazing recipe "${data?.title}" by ${data.author?.username} on Bokkah!`;
    const formattedDate = new Date(data?.created_at!).toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
    const fullShareText = `This recipe was posted on Bokkah in ${formattedDate}! 🍽️✨ Discover more inspiring recipes or build your own AI-generated recipes at www.bokkah.com!`;

    content = (
      <Section
        title={recipe.title}
        wrapperClasses="max-w-4xl lg:max-w-[60rem] m-auto"
        hasFavoriteIcon
        recipe={recipe as any}
      >
        <RecipeArticle recipe={recipe} />
        <aside className="py-12 flex justify-center gap-4 print:hidden">
          {isSameUser && (
            <>
              <Button isPrimary to="edit" className="flex gap-2 items-center">
                <PencilIcon className="w-4 h-4" /> Edit Recipe
              </Button>
              <Button isDestructive onClick={handleStartDelete} className="flex gap-2 items-center">
                <TrashIcon className="w-4 h-4" /> Delete
              </Button>
            </>
          )}
          <Button
            isPrimary={!isSameUser}
            onClick={() => setIsShareModalOpen(true)}
            className="flex gap-2 items-center"
          >
            <ShareIcon className="size-4" /> Share
          </Button>
          <Button onClick={() => window.print()} className="flex gap-2 items-center">
            <PrinterIcon className="size-4" /> Print
          </Button>

          {isShareModalOpen && (
            <Modal onClose={() => setIsShareModalOpen(false)}>
              <button
                onClick={() => setIsShareModalOpen(false)}
                className="p-2 -m-2 rounded-lg hover:bg-white absolute right-4 top-4 focus:outline-none focus:shadow-outline focus:ring-4 ring-gray-300 transition-all"
              >
                <XMarkIcon className="size-4 text-gray-600" />
              </button>
              <H2 title="Share" className="text-center mb-4" />
              {/*<p className="mt-4 mb-8 tracking-wide text-center">{fullShareText}</p>*/}
              <Share url={websiteUrl} title={data.title} image={data.image} />
            </Modal>
          )}
        </aside>
      </Section>
    );
  }

  return (
    <>
      {data && (
        // @ts-ignore
        <Helmet>
          <title>{`"${data?.title}" by ${data?.author?.username}`}</title>
          <meta property="og:title" content={`"${data?.title}" by ${data?.author?.username}`} />
          <meta property="og:image" content={data?.image} />
          <meta
            property="og:description"
            content={`Recipe added in "${data?.category.name}" on ${formatDate(data?.created_at)}.`}
          />
        </Helmet>
      )}

      {isDeleting && (
        <Modal>
          <H2 title="Are you sure?" />
          <p>Do you really want to delete this recipe? This action cannot be undone.</p>
          <div className="flex justify-end gap-4 mt-8">
            {isPendingDeletion && <p>Deleting, please wait...</p>}
            {!isPendingDeletion && (
              <>
                <button onClick={handleStopDelete} className="py-2 px-4 rounded-lg border border-gray-400">
                  Cancel
                </button>
                <button
                  onClick={handleDelete}
                  className="py-2 px-4 rounded-lg border text-red-100 bg-red-600 hover:bg-red-700"
                >
                  Delete
                </button>
              </>
            )}
          </div>
          {isErrorDeleting && (
            <ErrorBlock
              title="Failed to delete recipe"
              message={deleteError?.message || 'Failed to delete recipe, please try again later.'}
            />
          )}
        </Modal>
      )}
      {content}
    </>
  );
}
