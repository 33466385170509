export const CATEGORIES = [
  {
    name: 'Breakfast',
    id: 8,
  },
  {
    name: 'Desserts',
    id: 3,
  },
  {
    name: 'Drinks',
    id: 4,
  },
  {
    name: 'Fermented & Preserved',
    id: 9,
  },
  {
    name: 'Fish',
    id: 1,
  },
  {
    name: 'Meals',
    id: 2,
  },
  {
    name: 'Snacks',
    id: 10,
  },
  {
    name: 'Soups',
    id: 5,
  },
  {
    name: 'Starters',
    id: 6,
  },
  {
    name: 'Vegetarian',
    id: 7,
  },
];