import React from 'react';
import ContentBox from '../components/UI/ContentBox';
import Section from '../components/UI/Section';
import Badge from '../components/UI/Badge';

export default function Podcast() {
  return (
    <Section title="The Bokkah Podcast" hasIcons={false} wrapperClasses="max-w-lg m-auto">
      <ContentBox className="flex flex-col gap-12">
        <iframe
          title="Bokkah Podcast on Spotify Player"
          style={{ borderRadius: '12px' }}
          src="https://open.spotify.com/embed/show/6zzysPKcIYdRWZ2LvgZfck?utm_source=generator&theme=0"
          width="100%"
          height="352"
          frameBorder="0"
          // allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        />
        <p className="text-sm text-gray-800 tracking-wide">
          Welcome to the Bokkah Podcast, where food lovers from around the world gather to explore diverse recipes,
          healthy living tips, and creative kitchen tricks. Join us weekly for bite-sized episodes (5-20 minutes)
          featuring delicious global cuisines, seasonal favorites, and insights into the art of cooking. Whether you're
          a seasoned chef or just starting out, there's something here for everyone. Let's make cooking fun, healthy,
          and inspiring!
        </p>
        <div className="-mt-4 flex justify-center mx-auto items-center gap-4 text-xs text-gray-600 text-balance">
          <Badge type="ai" />
          <span>DISCLAIMER: This podcast was created using AI tools: Bokkah's AI recipes and NotebookLM.</span>
        </div>
      </ContentBox>
    </Section>
  );
}
