import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';
import { createNewRecipe, queryClient, sendRecipeUrl } from '../../util/http';
import { useUser } from '../../store/UserContext';
import RecipeForm from './RecipeForm';
import Section from '../UI/Section';
import Button from '../UI/Button';
import { uploadImage } from '../../util/image';
import { useForm } from 'react-hook-form';
import LoadingIndicator from '../UI/LoadingIndicator';
import { SparklesIcon } from '@heroicons/react/24/outline';
import ErrorBlock from "../UI/ErrorBlock";

const labelClasses = 'block text-gray-800 font-bold mb-4 cursor-pointer';
const inputClasses =
  'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-4 transition-all';

export default function AINewRecipeFromUrl() {
  const navigate = useNavigate();
  const { user } = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // ✅ Track error state
  const [aiResponse, setAiResponse] = useState(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isDraft, setIsDraft] = useState(false);

  function handleImageChange(file: File) {
    if (!file) return;
    setImageFile(file);
  }

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: sendRecipeUrl,
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['recipes'] });
      setAiResponse(response);
    },
    onError: (error: any) => {
      setIsSubmitting(false);
      console.error("Detailed error:", error?.message);
      setErrorMessage("Your recipe contains inappropriate content. Please review and try again.");
    },
  });
  const { register, getValues, formState: { errors }, } = useForm({
    defaultValues: {
      url: '',
    },
  });

  const {
    mutate: mutateRecipe,
    isPending: isPrendingRecipe,
    isError: isErrorRecipe,
    error: erroRecipe,
  } = useMutation({
    mutationFn: createNewRecipe,
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['recipes'] });
      navigate('/recipes');
    },
      onError: (error: any) => {
      setIsSubmitting(false);
      console.error("Detailed error:", error?.message);
      setErrorMessage("Your recipe contains inappropriate content. Please review and try again.");
    },
  });

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage(null);

    const url = getValues('url');

    if (!url.match(/^(https?:\/\/)?([\w-]+(\.[\w-]+)+\/?)([\w-.,@?^=%&:/~+#]*[\w-@?^=%&/~+#])?$/)) {
      alert("Invalid URL format");
      return;
    }

    setIsSubmitting(true);
    mutate({ url });
  }

  function handleSubmitRecipe(formData: any) {
    setIsSubmitting(true);

    const ingredients = [];
    const steps = [];

    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (key.startsWith('ingredients.')) {
          // @ts-ignore
          ingredients.push(formData[key]);
        } else if (key.startsWith('steps.')) {
          // @ts-ignore
          steps.push(formData[key]);
        }
      }
    }

    formData = {
      title: formData.title,
      // category: formData.category,
      category: 1,
      image: formData.image ?? '/logo512.png',
      ingredients: ingredients,
      steps: steps,
      author: user.uid,
      draft: formData.draft,
      source: getValues('url'),
      ai: true,
    };

    const handleMutation = (data: any) => {
      mutateRecipe(data, {
        onSuccess: () => {
          setIsSubmitting(false);
        },
        onError: (error: any) => {
          setIsSubmitting(false);
        },
      });
    };

    // Check if an image file is selected
    if (!formData.draft && formData.image) {
      uploadImage(formData.image)
        .then((imageUrl) => {
          formData.image = imageUrl;
          handleMutation({ recipe: formData });
        })
        .catch((uploadError) => {
          console.error('Error uploading image:', uploadError);
          setIsSubmitting(false);
        });
    } else {
      setIsDraft(true);
      handleMutation({ recipe: formData });
    }
  }

  return (
    <>
      {!aiResponse && (
        <Section isNarrow title="Add Recipe From Link" hasIcons={false} wrapperClasses="mx-2 sm:mx-auto">
          <div className="px-6 py-8 sm:p-8 flex flex-col justify-start items-stretch bg-white shadow-xl rounded-xl min-h-56">
            {isSubmitting && (
              <div className="flex flex-col p-8 gap-8 justify-center items-center">
                <LoadingIndicator />
                <div className="flex gap-2 items-center">
                  <SparklesIcon className="size-6" />
                  <p className="text-sm">Generating recipe with AI...</p>
                </div>
              </div>
            )}
            {!isSubmitting && (
              <form onSubmit={handleSubmit}>
                <div className="mb-8">
                  <label htmlFor="url" className={labelClasses}>
                    Link URL
                  </label>
                  <input
                    id="url"
                    {...register('url', {
                      required: "URL is required",
                      pattern: {
                        value: /^(https?:\/\/)?([\w-]+(\.[\w-]+)+\/?)([\w-.,@?^=%&:/~+#]*[\w-@?^=%&/~+#])?$/,
                        message: "Please enter a valid URL",
                      },
                    })}
                    placeholder="Insert the link for the recipe"
                    className={inputClasses}
                    required
                    autoFocus
                    autoComplete="off"
                  />
                  {errors.url && <p className="text-red-500 text-xs mt-1">{errors.url.message}</p>}
                </div>
                <div className="flex gap-4">
                  <Button type="submit" isPrimary disabled={isSubmitting}>
                    <div className="flex gap-2 items-center ">
                      <SparklesIcon className="size-4" />
                      Send Link
                    </div>
                  </Button>
                </div>
              </form>
            )}
          </div>
        </Section>
      )}

      {aiResponse && (
        <Section isNarrow title="Edit Recipe From Link" hasIcons={false}>
          <RecipeForm
            inputData={aiResponse}
            onSubmit={handleSubmitRecipe}
            onImageChange={handleImageChange}
            markAsDraft={true}
          >
            {isPrendingRecipe && 'Submitting...'}
            {!isPrendingRecipe && (
              <div className="flex gap-4">
                <button type="submit" className="border rounded-md px-4 py-2 bg-gray-700 text-gray-50">
                  Create Recipe
                </button>
                <Link to="../" className="px-4 py-2">
                  Cancel
                </Link>
              </div>
            )}
          </RecipeForm>
          {errorMessage && (
            <ErrorBlock
              title="Failed to create recipe"
              message={errorMessage || 'Failed to create recipe. Please check your inputs and try again later.'}
              onClose={() => setErrorMessage(null)}
            />
          )}
        </Section>
      )}
    </>
  );
}
