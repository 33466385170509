import React, { useState } from 'react';
import {
  FacebookShareButton,
  PinterestShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
} from 'react-share';
import { LinkIcon } from '@heroicons/react/24/outline';
import QRCodeGenerator from "./QRCodeGenerator";

type ShareProps = { url: string; title: string; image: string };

export default function Share({ url, title, image }: ShareProps) {
  const [isCopied, setIsCopied] = useState(false);
  const shareText = `🍽️ Check out this amazing recipe "${title}" on Bokkah!`;
  const buttonClasses =
    'bg-gray-50 hover:bg-white rounded-full p-4 hover:opacity-85 hover:bg-white focus:outline-none focus:shadow-outline focus:ring-4 transition-all rounded-full';

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <section className="flex flex-col gap-8 mb-8">
      <ul className="flex flex-wrap justify-center sm:justify-between items-end gap-8 py-4 sm:gap-4 rounded-xl text-gray-700">
        <li>
          <button
            onClick={copyToClipboard}
            className="flex justify-center items-center bg-gray-50 hover:bg-white rounded-full size-16 sm:size-14 focus:outline-none focus:shadow-outline focus:ring-4 transition-all"
          >
            <LinkIcon className="size-6" />
          </button>
          <span className="text-xs flex justify-center mt-2">{isCopied ? 'Copied' : 'Copy link'}</span>
        </li>
        <li>
          <PinterestShareButton url={url} media={image} description={shareText} className={buttonClasses}>
            <img src="/icons/pinterest.svg" alt="Pinterest logo" className="size-16 sm:size-14" />
          </PinterestShareButton>
          <span className="text-xs flex justify-center">Pinterest</span>
        </li>
        <li>
          <WhatsappShareButton url={url} title={shareText} className={buttonClasses}>
            <img src="/icons/whatsapp.svg" alt="WhatsApp logo" className="scale-[1.14] size-16 sm:size-14" />
          </WhatsappShareButton>
          <span className="text-xs flex justify-center">WhatsApp</span>
        </li>
        <li>
          <FacebookMessengerShareButton url={url} className={buttonClasses} appId="https://bokkah.com">
            <img src="/icons/messenger.svg" alt="Facebook logo" className="scale-[0.82] size-16 sm:size-14" />
          </FacebookMessengerShareButton>
          <span className="text-xs flex justify-center">Messenger</span>
        </li>
        <li>
          <FacebookShareButton url={url} className={buttonClasses}>
            <img src="/icons/facebook.svg" alt="Facebook logo" className="scale-[1.13] size-16 sm:size-14" />
          </FacebookShareButton>
          <span className="text-xs flex justify-center">Facebook</span>
        </li>
        {/*<li>*/}
        {/*  <TwitterShareButton url={url} title={shareText} className={buttonClasses}>*/}
        {/*    <img src="/icons/x.svg" alt="X/Twitter logo" className="scale-105 size-16 sm:size-14"/>*/}
        {/*  </TwitterShareButton>*/}
        {/*  <span className="text-xs flex justify-center">X</span>*/}
        {/*</li>*/}
      </ul>

      {/* QR CODE from websiteUrl */}
      <QRCodeGenerator url={url} />
    </section>
  );
}
