import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { NavLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { fetchUser } from '../../util/http';
import { useUser } from '../../store/UserContext';
import Section from '../UI/Section';
import Button from '../UI/Button';
import LoadingIndicator from '../UI/LoadingIndicator';
import AddToFriendsButton from '../UI/AddToFriendsButton';
import UserRecipes from './UserRecipes';
import {ExclamationTriangleIcon, MapPinIcon, PencilIcon, UsersIcon, XMarkIcon} from '@heroicons/react/24/outline';

export default function UserDetail() {
  const params = useParams();
  const { user } = useUser();

  const { data, isPending } = useQuery({
    queryKey: ['users', params.username],
    queryFn: ({ signal }) => fetchUser({ username: params.username, signal }),
  });

  let content: JSX.Element | null = null;

  if (isPending) {
    content = (
      <div className="mt-4">
        <LoadingIndicator />
      </div>
    );
  }

  if (data) {
    content = (
      <Section>
        {user?.is_inactive && (
          <div className={`w-[90%] m-auto mb-48 lg:w-auto max-w-[32rem] px-2 py-6 rounded-lg shadow-xl bg-rose-100`}>
            <div className="flex flex-col sm:flex-row items-center text-center sm:text-start gap-4 sm:gap-6 px-6 sm:pr-12 pb-8 sm:pb-4 pt-2">
              <ExclamationTriangleIcon className="size-16 mt-2 flex text-rose-500 shrink-0" />
              <div>
                <h2 className="font-bold text-lg mb-2">Account Deactivated</h2>
                <p className="text-pretty">
                  Your account is currently deactivated, which means your profile is not visible to others. You can
                  reactivate your account anytime from your{' '}
                  <NavLink to={`/users/${user?.username}/edit/`} className="font-bold underline hover:underline-none">
                    account settings
                  </NavLink>
                  .
                </p>
              </div>
            </div>
          </div>
        )}

        <div className=" bg-white shadow-xl rounded-xl m-4 sm:m-auto max-w-2xl">
          <div className=" flex justify-center mt-28">
            <img
              src={data.image ?? '/logo512.png'}
              alt={`Portrait of ${data.name}`}
              className=" rounded-full size-[12rem] relative -mt-36 bg-skeleton border-4 border-white shadow-lg"
              loading="lazy"
            />
          </div>
          <div className="flex px-12 pb-6 gap-12 flex-col sm:flex-row text-pretty">
            <div className="w-full text-center text-pretty">
              <div className="flex flex-col justify-center items-center gap-2 mb-6">
                <h1 className="text-center text-2xl font-bold p-6 pb-0 text-gray-700">{data.name ?? data.username}</h1>
                {data.country && (
                  <h3 className='text-xs tracking-wide text-gray-600 flex items-center gap-2'>
                    <MapPinIcon className="size-4 text-gray-500"/> {data.country}
                  </h3>
                )}
              </div>
              {data.bio && <p className="text-balance">{data.bio}</p>}
            </div>
          </div>
          <div className="px-8 pb-12 flex flex-col sm:flex-row gap-4 justify-center w-full text-center md:text-left">
            {user && user?.id !== data.uid ? (
              <AddToFriendsButton user={user} friendData={data}/>
            ) : (
              user && (
                <>
                  <Button
                    to={`/users/${data.username}/edit/`}
                    isPrimary
                    className="flex gap-3 items-center justify-center"
                  >
                    <PencilIcon className="size-4" /> Edit Profile
                  </Button>

                  <Button to={`/users/${data.username}/friends/`} className="flex gap-3 items-center justify-center">
                    <UsersIcon className="size-4" /> Friends
                  </Button>
                </>
              )
            )}
          </div>
        </div>
        <div className="mt-12">{data.recipes && <UserRecipes />}</div>
      </Section>
    );
  }

  return (
    <>
      {data && (
        // @ts-ignore
        <Helmet>
          <title>{`${data?.name}'s profile`}</title>
          <meta property="og:title" content={`${data?.name}'s profile`} />
          <meta property="og:image" content={data?.image} />
          <meta property="og:description" content={`${data?.bio}`} />
        </Helmet>
      )}
      <div className="pt-12">
        {content}
      </div>
    </>
  );
}
