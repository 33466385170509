import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Link, useParams, useNavigation, useNavigate } from 'react-router-dom';

import RecipeForm from './RecipeForm';
import ErrorBlock from '../UI/ErrorBlock';
import { fetchRecipe, updateRecipe, queryClient } from '../../util/http';
import { uploadImage } from '../../util/image';
import Section from '../UI/Section';
import { useUser } from '../../store/UserContext';

import { Recipe } from '../../types';

export default function EditRecipe() {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useNavigation();

  const { user } = useUser();
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // ✅ Track error state

  const { data, isError, isLoading, error } = useQuery({
    queryKey: ['recipes', params.recipeId],
    queryFn: ({ signal }) => fetchRecipe({ id: params.recipeId, signal }),
  });

  // Redirect only after data and user are loaded
  useEffect(() => {
    if (!isLoading && !isError && user && data) {
      if (user.uid !== data.author?.uid) {
        navigate(`/recipes/${params.recipeId}`);
      }
    }
  }, [user, data, isLoading, isError, navigate, params]);

  const {
    mutate,
    // isPending,
    // isError: isErrorMutate,
    // error: errorMutate,
  } = useMutation({
    mutationFn: updateRecipe,
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['recipes'] });
      queryClient.invalidateQueries({ queryKey: ['collections'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['user-recipes'] });
      navigate(`/recipes/${params.recipeId}`); // Go back to recipe
    },
    onError: (error: any) => {
    setIsSubmitting(false);
    console.error("Detailed error:", error?.message);
    setErrorMessage("Your recipe contains inappropriate content. Please review and try again.");
  },
  });

  function handleImageChange(file: File | null) {
    setImageFile(file);
  }

  function handleSubmit(formData: Recipe) {
    setIsSubmitting(true);
    setErrorMessage(null);

    const ingredients = [];
    const steps = [];

    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (key.startsWith('ingredients.')) {
          // @ts-ignore
          ingredients.push(formData[key]);
        } else if (key.startsWith('steps.')) {
          // @ts-ignore
          steps.push(formData[key]);
        }
      }
    }

    formData = {
      title: formData.title,
      category: formData.category,
      image: formData.image,
      ingredients: ingredients,
      steps: steps,
      draft: formData.draft,
    };

    const handleMutation = (data: any) => {
      mutate(data, {
        onSuccess: () => {
          setIsSubmitting(false);
        },
        onError: (error: any) => {
          setIsSubmitting(false);
        },
      });
    };

    // Check if an image file is selected
    if (imageFile) {
      uploadImage(imageFile, user.username)
        .then((imageUrl) => {
          formData.image = imageUrl;

          handleMutation({ id: params.recipeId, recipe: formData });
        })
        .catch((uploadError) => {
          console.error('Error uploading image:', uploadError);
          setIsSubmitting(false);
        });
    } else {
      handleMutation({ id: params.recipeId, recipe: formData });
    }
  }

  let content: JSX.Element | null = null;

  if (isError) {
    content = (
      <>
        <ErrorBlock
          title="Failed to load recipe."
          message={error?.message || 'Failed to load recipe. Please check your inputs and try again later.'}
        />
        <div className="form-actions">
          <Link to="../" className="button">
            Okay
          </Link>
        </div>
      </>
    );
  }

  if (data && user) {
    content = (
      <Section isNarrow title="Edit Your Recipe" hasIcons={false}>
        <RecipeForm inputData={data} onSubmit={handleSubmit} onImageChange={handleImageChange}>
          {state === 'submitting' ? (
            <p>Sending data...</p>
          ) : (
            <div className="flex gap-4">
              <button
                type="submit"
                className="border rounded-md px-4 py-2 bg-gray-700 text-gray-50"
                disabled={state === 'loading' || isSubmitting}
              >
                Update
              </button>
              <Link to="../" className="px-4 py-2">
                Cancel
              </Link>
            </div>
          )}
        </RecipeForm>
        {errorMessage && (
          <ErrorBlock
            title="Failed to create recipe"
            message={errorMessage || 'Failed to edit recipe. Please check your inputs and try again later.'}
            onClose={() => setErrorMessage(null)}
          />
        )}
      </Section>
    );
  }

  return <div>{content}</div>;
}

// This loader() will generate the initial cache
// and it will be, thus, faster to show data when the component executes
// @ts-ignore
export function loader({ params }) {
  // `params` Obj provided by React

  return queryClient.fetchQuery({
    queryKey: ['recipes', params.recipeId],
    queryFn: ({ signal }) => fetchRecipe({ id: params.recipeId, signal }),
  });
}
