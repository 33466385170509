import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Link, redirect, useParams, useSubmit, useNavigation, useNavigate } from 'react-router-dom';

import UserForm from './UserForm';
import ErrorBlock from '../UI/ErrorBlock';
import { fetchUser, updateUser, queryClient, fetchUserByUid, deactivateUser, activateUser } from '../../util/http';
import { uploadImage } from '../../util/image';
import { useUser } from '../../store/UserContext';
import Button from '../UI/Button';
import Section from '../UI/Section';
import Modal from '../UI/Modal';
import H2 from '../UI/H2';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

export default function EditUser() {
  const navigate = useNavigate();
  const params = useParams();
  const submit = useSubmit();
  const { state } = useNavigation();
  const { user, signOut } = useUser();
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isDeactivating, setIsDeactivating] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const [isSubmiting, setIsSubmitting] = useState<boolean>(false);

  const { data, isError, isLoading, error } = useQuery({
    queryKey: ['users', params.username],
    queryFn: ({ signal }) => fetchUserByUid({ uid: user.uid, signal }),
  });

  // Redirect only after data and user are loaded
  useEffect(() => {
    if (!isLoading && !isError && user && data) {
      if (user.uid !== data?.uid) {
        navigate(`/users/${params.username}`);
      }
    }
  }, [user, data, isLoading, isError, navigate, params]);

  function handleImageChange(file: File) {
    setImageFile(file);
  }

  function handleSubmit(formData: HTMLFormElement) {
    setIsSubmitting(true);

    // Check if an image file is selected
    if (imageFile) {
      uploadImage(imageFile, user.username)
        .then((imageUrl) => {
          formData.image = imageUrl;
          submit(formData, {
            method: 'PUT',
          });

          // Update current logged user's avatar
          const loggedUserAvatar = document.querySelector('#loggedUserAvatar img') as HTMLImageElement;
          if (loggedUserAvatar) {
            loggedUserAvatar.src = imageUrl;
          }
        })
        .catch((uploadError) => {
          console.error('Error uploading image:', uploadError);
        });
    } else {
      submit(formData, {
        method: 'PUT',
      });
    }
  }

  const {
    mutate: deactivateUserMutation,
    isPending: isPendingDeactivation,
    isError: isErrorDeactivating,
    error: deactivationError,
  } = useMutation({
    mutationFn: deactivateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'], refetchType: 'none' });
      signOut();
      alert(
        'Your account has been successfully deactivated. You are now invisible to others until you reactivate it.'
      );
      navigate(`/`);
    },
  });

  const {
    mutate: activateUserMutation,
    isPending: isPendingActivation,
    isError: isErrorActivating,
    error: activationError,
  } = useMutation({
    mutationFn: activateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'], refetchType: 'none' });
      signOut();
      alert('Your account has been activated! Please log in again to continue.');
      navigate(`/login`);
    },
  });

  function handleStartDeactivation() {
    setIsDeactivating(true);
  }

  function handleStopDeactivation() {
    setIsDeactivating(false);
  }

  function handleDeactivate() {
    deactivateUserMutation({ username: params.username });
  }

  function handleStartActivation() {
    setIsActivating(true);
  }

  function handleStopActivation() {
    setIsActivating(false);
  }

  function handleActivate() {
    activateUserMutation({ username: params.username });
  }

  let content: JSX.Element | null = null;

  if (isError) {
    content = (
      <>
        <ErrorBlock
          title="Failed to load user."
          message={error?.message || 'Failed to load user. Please check your inputs and try again later.'}
        />
        <div className="form-actions">
          <Link to="../" className="button">
            Okay
          </Link>
        </div>
      </>
    );
  }

  if (data && user) {
    content = (
      <Section title="Edit Your Profile" hasIcons={false} wrapperClasses="max-w-3xl mx-2 mx-auto">
        <UserForm inputData={data} onSubmit={handleSubmit} onImageChange={handleImageChange}>
          {isSubmiting || state === 'submitting' ? (
            <p>Sending data...</p>
          ) : (
            <div className="w-full flex gap-4 justify-between ">
              <div className="flex gap-4">
                <Button type="submit" isPrimary>
                  Update Profile
                </Button>
                <Button to="../" className="px-4 py-2">
                  Cancel
                </Button>
              </div>

              <div>
                {!user?.is_inactive ? (
                  <Button type="button" isDestructive onClick={handleStartDeactivation}>
                    Deactivate
                  </Button>
                ) : (
                  <Button type="button" isSuccess onClick={handleStartActivation}>
                    Activate
                  </Button>
                )}
              </div>
            </div>
          )}
        </UserForm>
        {isDeactivating && (
          <Modal onClose={() => setIsDeactivating(false)}>
            <H2 title="Are you sure?" />
            <p className="text-pretty">Do you really want to deactivate your account?</p>
            <div className="flex justify-end gap-4 mt-8">
              {isPendingDeactivation && <p>Deactivating, please wait...</p>}
              {!isPendingDeactivation && (
                <>
                  <button
                    onClick={handleStopDeactivation}
                    className="py-2 px-4 rounded-lg border border-gray-400 outline-none focus:ring-4 transition-all"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleDeactivate}
                    className="py-2 px-4 rounded-lg border text-red-100 bg-red-600 hover:bg-red-700 outline-none focus:ring-4 transition-all"
                  >
                    Yes, deactivate account
                  </button>
                </>
              )}
            </div>
            {isErrorDeactivating && (
              <ErrorBlock
                title="Failed to deactivate user"
                message={deactivationError?.message || 'Failed to deactivate user, please try again later.'}
              />
            )}
          </Modal>
        )}

        {isActivating && (
          <Modal onClose={() => setIsActivating(false)}>
            <H2 title="Are you sure?" />
            <p className="text-pretty">Do you really want to activate your account?</p>
            <div className="flex justify-end gap-4 mt-8">
              {isPendingDeactivation && <p>Activating, please wait...</p>}
              {!isPendingDeactivation && (
                <>
                  <button
                    onClick={handleStopActivation}
                    className="py-2 px-4 rounded-lg border border-gray-400 outline-none focus:ring-4 transition-all"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleActivate}
                    className="py-2 px-4 rounded-lg border text-lime-100 bg-lime-600 hover:bg-lime-700 outline-none focus:ring-4 transition-all"
                  >
                    Activate
                  </button>
                </>
              )}
            </div>
            {isErrorActivating && (
              <ErrorBlock
                title="Failed to activate user"
                message={activationError?.message || 'Failed to deactivate user, please try again later.'}
              />
            )}
          </Modal>
        )}
      </Section>
    );
  }

  return <div>{content}</div>;
}

// This loader() will generate the initial cache
// and it will be, thus, faster to show data when the component executes
// @ts-ignore
export function loader({ params }) {
  // `params` Obj provided by React

  return queryClient.fetchQuery({
    queryKey: ['users', params.username],
    queryFn: ({ signal }) => fetchUser({ username: params.username, signal }),
  });
}

// Will be trigger by React when a form is submitted
// @ts-ignore
export async function action({ request, params }) {
  const formData = await request.formData(); // Built in method provided by React
  const updateUserData = Object.fromEntries(formData);

  await updateUser({ username: params.username, user: updateUserData });
  await queryClient.invalidateQueries({ queryKey: ['users'] });
  return redirect('../');
}
