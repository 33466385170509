import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import SwitchField from '../UI/SwitchField';

export default function CollectionForm({
  inputData,
  onSubmit,
  // user,
  children,
}: {
  inputData?: any;
  onSubmit: (data: any) => void;
  // user: any;
  children: React.ReactNode;
}) {
  const labelClasses = 'block text-gray-800 font-bold mb-4 cursor-pointer';
  const inputClasses =
    'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-4 transition-all';

  const collection = inputData;
  const [collectionIsPrivate, setCollectionIsPrivate] = useState(collection?.private || false);

  const { register, getValues } = useForm({
    defaultValues: {
      id: collection?.id,
      name: collection?.name || '',
      description: collection?.description
        ? collection.description
            .split('\n') // Split the string by new lines
            .filter((line: string) => line.trim() !== '') // Remove empty or whitespace-only lines
            .join('\n') // Join back into a single string
        : '',
      users: collection?.users || '',
      private: collection?.private || false,
    },
  });

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement);
    let data = Object.fromEntries(formData);

    if (!data.private) {
      data.private = collectionIsPrivate;
    }

    onSubmit({ ...data });
  }

  return (
    <form className="p-8 bg-white shadow-xl rounded-xl" onSubmit={handleSubmit}>
      <div className="mb-8">
        <label htmlFor="name" className={labelClasses}>
          Collection name
        </label>
        <input
          id="name"
          name="name"
          placeholder="Your collection name"
          className={inputClasses}
          defaultValue={getValues('name')}
          maxLength={120}
          required
          autoFocus={collection ? false : true}
          autoComplete="off"
        />
      </div>

      <div className="mb-8">
        <label htmlFor="description" className={labelClasses}>
          Collection description
        </label>
        <textarea
          id="description"
          {...register('description')}
          rows={5}
          maxLength={460}
          placeholder="Writing a small paragraph about this new collection."
          className={inputClasses}
        />
      </div>

      <div className="mb-8">
        <label htmlFor="private" className={labelClasses}>
          <div className="flex gap-4 justify-between items-center">
            <input
              id="private"
              {...register('private')}
              type="checkbox"
              className="sr-only peer"
              defaultChecked={getValues('private')}
              onClick={() => setCollectionIsPrivate(!collectionIsPrivate)}
            />
            <p>
              Would you like to mark this collection as "private"?
              <br />
              <small className="font-normal">
                {collectionIsPrivate
                  ? 'Only the you and people sharing the collection can view and contribute to this collection.'
                  : 'Everyone can view the collection and members can contribute to this collection.'}
              </small>
            </p>
            <SwitchField />
          </div>
        </label>
      </div>

      <div className="pt-4 min-h-[4rem] flex items-center">{children}</div>
    </form>
  );
}
