import React from 'react';
import { useUser } from '../../store/UserContext';
import Section from '../UI/Section';
import { useQuery } from '@tanstack/react-query';
import { fetchUserDashboard } from '../../util/http';
import LoadingIndicator from '../UI/LoadingIndicator';
import ErrorBlock from '../UI/ErrorBlock';
import { Collection } from '../../types/collection';
import CollectionCard from '../Collections/CollectionCard';
import RecipeCard from '../Recipes/RecipeCard';
import { Recipe, User } from '../../types';
import UserCard from '../Users/UserCard';
import { NavLink } from 'react-router-dom';
import ContentBox from '../UI/ContentBox';
import { BookOpenIcon, SquaresPlusIcon, UsersIcon } from '@heroicons/react/24/outline';

export default function Dashboard() {
  const { user } = useUser();
  const uid = user?.uid;

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['dashboard', { uid, max: 99 }],
    queryFn: ({ signal, queryKey }) => fetchUserDashboard({ uid, signal }),
  });

  let content: JSX.Element | null = null;

  if (isPending) {
    content = <LoadingIndicator />;
  }

  if (isError) {
    content = <ErrorBlock title="An error occurred" message={error?.message || 'Failed to fetch notifications.'} />;
  }

  if (data) {
    content = (
      <>
        {data.feed?.latest_added_friends?.length >= 1 && (
          <Section title={`Latest added friends (${data.feed?.latest_added_friends?.length})`} hasIcons={false}>
            <div className="flex lg:px-12 gap-8 mx-2 flex-wrap justify-center text-gray-800">
              {data.feed?.latest_added_friends.map((friend: User) => (
                <UserCard key={friend.uid} user={friend} />
              ))}
            </div>
          </Section>
        )}

        {data.feed?.latest_recipes?.length >= 1 && (
          <Section title={`Latest added recipes`} hasIcons={false}>
            <div className="flex mx-2 flex-wrap justify-center text-gray-800">
              {data.feed?.latest_recipes.map((recipe: Recipe) => (
                <RecipeCard key={recipe.id} recipe={recipe} />
              ))}
            </div>
          </Section>
        )}

        {data.feed?.latest_updated_collections?.length >= 1 && (
          <Section title={`Latest activity in Collections`} hasIcons={false}>
            <div className="flex gap-8 mx-2 flex-wrap justify-center text-gray-800">
              {data.feed?.latest_updated_collections.map((collection: Collection) => (
                <CollectionCard key={collection.id} collection={collection} />
              ))}
            </div>
          </Section>
        )}
      </>
    );
  }

  if (data?.feed?.latest_added_friends.length === 0) {
    content = (
      <Section title={`Welcome to Your News Feed`} hasIcons={false}>
        <div className="flex lg:px-12 gap-8 flex-wrap justify-center text-gray-600 max-w-[48rem] m-auto tracking-wider leading-6">
          <ContentBox>
            <div className="flex flex-col gap-8 px-4 pb-4 rounded-md justify-center">
              {/*<InformationCircleIcon className='mt-4 w-48 h-48 text-gray-100 m-auto'/>*/}
              <SquaresPlusIcon className="mt-4 w-48 h-48 text-gray-100 m-auto" />
              <p className="leading-6">
                It looks like your news feed is waiting for its first update!
                <br />
                Start by connecting with{' '}
                <NavLink to={`/users`} className="font-bold text-gray-800 group">
                  <UsersIcon className="inline-flex size-4" /> <span className="group-hover:underline">friends</span>
                </NavLink>{' '}
                or following{' '}
                <NavLink to={`/collections`} className="font-semibold text-gray-800 group">
                  <BookOpenIcon className="inline-flex size-4" />{' '}
                  <span className="group-hover:underline">collections</span>
                </NavLink>
                .<br />
                Once you do, you’ll see the latest updates here.
              </p>
            </div>
          </ContentBox>
        </div>
      </Section>
    );
  }

  return <div className="flex flex-col gap-20 2xl:gap-24">{content}</div>;
}
