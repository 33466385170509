import React from 'react';
import RecipeCard from '../Recipes/RecipeCard';
import { Recipe, User } from '../../types';
import AddNewRecipeButton from '../UI/AddNewRecipeButton';
import { useParams, useSearchParams } from 'react-router-dom';
import { useUser } from '../../store/UserContext';
import { useQuery } from '@tanstack/react-query';
import {
  fetchUserCollectionsByUsername,
  fetchUserFavoriteRecipes,
  fetchUserFriendsRecipes,
  fetchUserRecipesByUsername
} from '../../util/http';
import LoadingIndicator from '../UI/LoadingIndicator';
import ErrorBlock from '../UI/ErrorBlock';
import Section from '../UI/Section';
import Tabs from '../UI/Tabs';
import { Collection } from '../../types/collection';
import AddNewCollectionButton from '../UI/AddNewCollectionButton';
import CollectionCard from '../Collections/CollectionCard';

export default function UserRecipes() {
  const params = useParams();
  const username = params.username;
  const { user } = useUser();
  const isSameUser = username === user?.username;
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') || 'latest';

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['user-recipes', params.username],
    queryFn: ({ signal }) => fetchUserRecipesByUsername({ signal, username }),
  });

  let content: JSX.Element | null = null;

  if (isPending) {
    content = (
      <div className="my-12">
        <LoadingIndicator />
      </div>
    );
  }

  if (isError) {
    content = <ErrorBlock title="An error occurred" message={error?.message || 'Failed to fetch user recipes.'} />;
  }

  // Get friends recipes
  const {
    data: friendsRecipes,
    isPending: isPendingFriendsRecipes,
    isError: isErrorFriendsRecipes,
    error: errorFriendsRecipes,
  } = useQuery({
    queryKey: ['friends-recipes', username, { max: 99 }],
    queryFn: ({ signal }) => fetchUserFriendsRecipes({ username, signal, max: 99 }),
  });

  if (isPendingFriendsRecipes) {
    content = (
      <div className="my-12">
        <LoadingIndicator />
      </div>
    );
  }

  if (isErrorFriendsRecipes) {
    content = (
      <ErrorBlock
        title="An error occurred"
        message={errorFriendsRecipes?.message || 'Failed to fetch friends recipes.'}
      />
    );
  }

  // Get favorite recipes
  const {
    data: favoriteRecipesData,
    isPending: isPendingFavoriteRecipes,
    isError: isErrorFavoriteRecipes,
    error: errorFavoriteRecipes,
  } = useQuery({
    queryKey: ['favorites', username],
    queryFn: ({ signal }) => fetchUserFavoriteRecipes({ username, signal }),
    enabled: !!user?.uid, // Only run query when user.uid is available
  });

  if (isPendingFavoriteRecipes) {
    content = (
      <div className="my-12">
        <LoadingIndicator />
      </div>
    );
  }

  if (isErrorFavoriteRecipes) {
    content = (
      <ErrorBlock
        title="An error occurred"
        message={errorFavoriteRecipes?.message || 'Failed to fetch favorite recipes.'}
      />
    );
  }

  // Get User Collections
  const {
    data: collectionsData,
    isPending: isPendingCollections,
    isError: isErrorCollections,
    error: errorCollections,
  } = useQuery({
    queryKey: ['user-collections', params.username],
    queryFn: ({ signal }) => fetchUserCollectionsByUsername({ signal, username: params.username }),
  });

  if (isPendingCollections) {
    content = (
      <div className="my-12">
        <LoadingIndicator />
      </div>
    );
  }

  if (isErrorCollections) {
    content = (
      <ErrorBlock
        title="An error occurred"
        message={errorCollections?.message || 'Failed to fetch user collections.'}
      />
    );
  }

  // 🔹 Filter recipes based on the active tab
  let filteredRecipes = data || [];
  let favoriteRecipes = favoriteRecipesData || [];
  let filteredCollections = collectionsData || [];

  if (activeTab === 'recipes') {
    filteredRecipes = filteredRecipes?.filter((recipe: any) => !recipe.draft);
  } else if (activeTab === 'drafts') {
    filteredRecipes = filteredRecipes?.filter((recipe: any) => recipe.draft);
  } else if (activeTab === 'favorites') {
    filteredRecipes = favoriteRecipes;
  } else if (activeTab === 'ai-creations') {
    filteredRecipes = filteredRecipes?.filter((recipe: any) => recipe.ai && !recipe.draft);
  } else if (activeTab === 'shared') {
    filteredRecipes = friendsRecipes;
  } else if (activeTab === 'collections') {
    filteredCollections = collectionsData;
  } else {
    filteredRecipes = filteredRecipes?.filter((recipe: any) => !recipe.draft);
  }

  // Recipes
  if (data || friendsRecipes) {
    if (!isSameUser) {
      filteredRecipes = filteredRecipes?.filter((recipe: any) => recipe.draft === false);
    }

    content = filteredRecipes?.length
      ? filteredRecipes?.map((recipe: Recipe) => <RecipeCard recipe={recipe} key={recipe.id} user={user} />)
      : !user && <p className="mt-12 text-sm text-gray-500">No recipes added yet.</p>;
  }
  // Recipes
  if (activeTab === 'favorites' && favoriteRecipes) {
    content = favoriteRecipes?.length
      ? favoriteRecipes?.map((recipe: Recipe) => <RecipeCard recipe={recipe} key={recipe.id} user={user} />)
      : !user && <p className="mt-12 text-sm text-gray-500">No recipes added yet.</p>;
  }

  // Collections
  if (activeTab === 'collections' && collectionsData) {
    content =
      filteredCollections.length === 0 ? (
        <div className="max-w-lg m-auto text-center">
          {isSameUser ? (
            <div className="mt-4">
              <AddNewCollectionButton />
            </div>
          ) : (
            <p className="mt-12 text-sm text-gray-500">No collections created yet.</p>
          )}
        </div>
      ) : (
        <div className="flex lg:px-12 gap-4 m-2 mx-[8px] flex-wrap justify-center text-gray-800">
          {isSameUser && (
            <div className="w-full sm:w-auto flex justify-strech">
              <AddNewCollectionButton />
            </div>
          )}
          {filteredCollections.map((collection: Collection) => (
            <CollectionCard key={collection.id} collection={collection} />
          ))}
        </div>
      );
  }

  // 🔹 Define tabs
  const tabs = [
    { label: 'Recipes', value: 'recipes', count: data?.length },
    ...(isSameUser ? [{ label: 'Drafts', value: 'drafts', count: data?.filter((r: any) => r.draft)?.length }] : []),
    ...(favoriteRecipes.length >= 1 ? [{ label: "Favorites", value: "favorites", count: favoriteRecipes?.length ?? 0 }] : []),
    { label: 'AI Creations', value: 'ai-creations', count: data?.filter((r: any) => r.ai)?.length },
    { label: 'Shared with Me', value: 'shared', count: friendsRecipes?.length },
    { label: 'Collections', value: 'collections', count: filteredCollections?.length },
  ];

  return (
    <Section hasIcons={false} wrapperClasses="w-full m-auto">
      <Tabs tabs={tabs} defaultTab="recipes" />
      <div className="mt-24 sm:mt-4 min-h-[calc(100dvh-11.5rem)]">
        <div className="flex flex-wrap justify-center">
          {data && isSameUser && !['collections', 'shared'].includes(activeTab) && <AddNewRecipeButton />}
          {content}
        </div>
      </div>
    </Section>
  );
}
