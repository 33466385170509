import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { fetchUsers } from '../../util/http';
import { useUser } from '../../store/UserContext';
import CountrySelect from '../UI/CountrySelect';
import ErrorBlock from '../UI/ErrorBlock';
import { PhotoIcon } from '@heroicons/react/24/outline';
import { User } from '../../types';

export default function UserForm({
  inputData,
  onSubmit,
  children,
  onImageChange,
  newUser,
}: {
  inputData?: any;
  onSubmit: (data: any) => void;
  children: React.ReactNode;
  onImageChange: (file: any) => void;
  newUser?: any;
}) {
  const [image, setImage] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [usernameTaken, setUsernameTaken] = useState(false); // State to track if the username is taken

  const labelClasses = 'block text-gray-800 font-bold mb-4 cursor-pointer';
  const inputClasses =
    'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-4 transition-all';

  const { user } = useUser();
  const userData = inputData;

  const { register, setValue, getValues } = useForm({
    defaultValues: {
      username: userData?.username || newUser?.username || '',
      name: userData?.name || newUser?.name || '',
      image: userData?.image || newUser?.image || '',
      bio: userData?.bio || '',
      email: user?.email || decodeURIComponent(newUser?.email !== 'null' ? newUser?.email : '') || '',
      country: userData?.country || '',
      uid: userData?.uid || newUser?.uid,
    },
  });

  const [selected, setSelected] = useState({ name: getValues('country') });

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target?.result as string;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const size = Math.min(img.width, img.height); // Take the smaller dimension
        canvas.width = size;
        canvas.height = size;

        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(
            img,
            (img.width - size) / 2, // Start x (center crop)
            (img.height - size) / 2, // Start y (center crop)
            size,
            size,
            0,
            0,
            size,
            size
          );
        }

        // Convert canvas to file
        canvas.toBlob((blob) => {
          if (blob) {
            const croppedFile = new File([blob], file.name, { type: file.type });
            setImageFile(croppedFile); // Save the cropped file
            setImage(URL.createObjectURL(croppedFile)); // Preview the cropped image
            console.log(croppedFile, 'croppedFile');
            onImageChange(croppedFile); // Pass the cropped file to the parent
          }
        }, file.type);
      };
    };
    reader.readAsDataURL(file);
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
      .replace(/[\s'"(){}<>@#$%^&*!?±§=,|`~:\\/â]/g, '') // Remove disallowed characters and accents
      .normalize('NFD') // Normalize accents
      .replace(/[\u0300-\u036f]/g, '') // Remove accents
      .toLowerCase(); // Convert to lowercase

    event.target.value = value; // Update the input field value
    checkUsernameAvailability(value); // Check username availability
  };

  // Check if the username is taken
  const checkUsernameAvailability = async (searchTerm: string) => {
    try {
      // @ts-ignore
      const users = await fetchUsers({ searchTerm });
      const isTaken: boolean = users.some((user: User) => user.username === searchTerm); // Check if any userData has the same username
      setUsernameTaken(isTaken);
      // return;
    } catch (error) {
      console.error('Error checking username availability:', error);
    }
  };

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    checkUsernameAvailability(getValues('username'));
    setValue('country', selected.name);

    const initialInage = userData?.image || newUser?.image || '';
    const formData = new FormData(event.target as HTMLFormElement);

    if (newUser) {
      formData.append('image', newUser.image);
      onImageChange(imageFile);
    } else {
      if (imageFile) {
        formData.append('image', imageFile);
        onImageChange(imageFile);
      } else {
        formData.append('image', initialInage);
        onImageChange(null);
      }
    }
    const data = Object.fromEntries(formData);

    data.email = getValues('email');

    onSubmit({ ...data });
  }

  return (
    <form className="px-6 py-8 sm:p-8 bg-white shadow-xl rounded-xl" onSubmit={handleSubmit}>
      <div className="mb-8">
        <label htmlFor="username" className={labelClasses}>
          Username
        </label>
        <input
          id="username"
          name="username"
          placeholder="Choose your username"
          defaultValue={getValues('username')}
          className={inputClasses + ` ${usernameTaken && !userData ? 'ring-4 ring-red-200' : ''}`}
          disabled={!newUser}
          onChange={handleUsernameChange}
          pattern="[a-zA-Z0-9_\-+]+" // Allow only letters, numbers, underscores, and dashes
          title="Please enter only lowercase characters"
          maxLength={24}
          required
          autoFocus={getValues('username') === ''}
        />
        {usernameTaken && !userData && (
          <ErrorBlock title="Username already taken" message="Please choose another username" />
        )}
      </div>

      <div className="flex flex-col sm:flex-row gap-8 mb-8">
        <div>
          <label htmlFor="image" className={labelClasses}>
            Profile Image
          </label>
          <div className="w-full sm:w-[12rem]">
            <label htmlFor="image">
              {userData?.image || newUser?.image || image ? (
                <img
                  src={image ?? getValues('image')}
                  alt={`${userData?.name ? userData.name : userData?.username}'s avatar`}
                  className=" rounded-full object-cover size-32 text-gray-200 bg-gray-200 border-2 border-white shadow-lg"
                />
              ) : (
                <div className="rounded-md object-cover w-full h-[8rem] bg-gray-100 flex items-center justify-center">
                  <PhotoIcon className="size-12 text-gray-300" />
                </div>
              )}
            </label>
          </div>
        </div>
        <div className="w-full sm:mt-12">
          <p className="mb-4 text-sm hidden sm:block">Would like to replace the image?</p>
          <input
            id="image"
            name="image"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="appearance-none rounded focus:p-2 focus:-m-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-4 transition-all"
            required={newUser?.image !== null ? false : userData?.image === undefined}
          />
        </div>
      </div>

      <div className="mb-8">
        <label htmlFor="name" className={labelClasses}>
          Name
        </label>
        <input id="name" {...register('name')} placeholder="Your name" className={inputClasses} required />
      </div>

      <div className="mb-8">
        <label htmlFor="email" className={labelClasses} style={{ cursor: 'default' }}>
          E-mail Address
        </label>
        <input
          id="email"
          type="email"
          {...register('email')}
          placeholder="Your e-mail address"
          className={inputClasses}
          required
          disabled={true}
        />
      </div>

      <div className="mb-8">
        <label htmlFor="country" className={labelClasses}>
          Country
        </label>

        {/* <input {...register('country')} value={selected} hidden /> */}
        <input {...register('country')} hidden />

        <CountrySelect selected={selected} setSelected={setSelected} id="country" name="country" />
      </div>

      <div className="mb-8">
        <label htmlFor="bio" className={labelClasses}>
          Your Bio
        </label>
        <textarea
          id="bio"
          rows={5}
          maxLength={600}
          {...register('bio')}
          placeholder="Writing a small paragraph about yourself."
          className={inputClasses}
        />
      </div>

      {newUser && (
        <>
          <input id="uid" type="text" {...register('uid')} hidden />
        </>
      )}

      <div className="pt-4 min-h-[4rem] flex items-center">{children}</div>
    </form>
  );
}
