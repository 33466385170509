import React, { useEffect, useRef } from "react";

export default function QRCodeGenerator({ url }: { url: string }) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    // @ts-ignore
    import("qrcode").then(QRCode => {
      if (canvasRef.current) {
        QRCode.toCanvas(canvasRef.current, url, {
          width: 180,
          margin: 1,
          color: {
            dark: "#374151",
            light: "#FFFFFF",
          },
        });
      }
    });
  }, [url]);

  return (
    <figure className="mx-auto flex flex-col items-center gap-4 rounded-xl shadow-md p-4 bg-white">
      <canvas ref={canvasRef} className="w-[179px] aspect-square bg-skeleton rounded-lg"></canvas>
      <figcaption className="text-xs text-gray-600">Scan to view the recipe</figcaption>
    </figure>
  );
}