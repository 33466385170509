import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useUser } from '../../store/UserContext';
import H1 from './H1';
import {
  ArrowLeftIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import RecipeOptionsButton from "./RecipeOptionsButton";

interface SectionProps {
  children: React.ReactNode;
  wrapperClasses?: string;
  isNarrow?: boolean;
  title?: string;
  hasRecipes?: boolean;
  hasFavoriteIcon?: boolean;
  hasIcons?: boolean;
  recipe?: { id: string; title: string; image: string };
}

export default function Section({
  children,
  wrapperClasses,
  isNarrow,
  title,
  hasRecipes,
  hasFavoriteIcon,
  hasIcons = true,
  recipe,
}: SectionProps) {
  const navigate = useNavigate();
  const { user } = useUser();

  const buttonClasses =
    'w-14 h-14 text-gray-400  rounded-full p-4 shadow hover:shadow-xl hover:bg-white hover:text-gray-700 transition outline-none focus:ring-4';

  return (
    <section
      className={
        isNarrow ? 'max-w-3xl m-auto' : hasRecipes ? 'm-auto' : ''
      }
    >
      <div className={`${wrapperClasses}`}>
        {title && (
          <div className='mx-4 sm:mx-2 bg-gray-50 print:bg-white pb-4 xl:pb-0 xl:rounded-full flex gap-4 m-auto justify-between items-center box-content'>
            {hasIcons ? (
              <button
                onClick={() => navigate(-1)}
                className={buttonClasses + ' flex print:hidden'}
                title='Go back'
              >
                <ArrowLeftIcon />
              </button>
            ) : (
              <span />
            )}
            <span className={`flex justify-center mx-auto ${hasIcons ? '' : 'md:mb-4'}`}><H1 title={title} /></span>
            {user && hasFavoriteIcon ? (
              recipe && (
                <div className='flex print:hidden'>
                  <RecipeOptionsButton recipeId={recipe.id} recipeTitle={recipe.title} recipeImage={recipe.image} />
                </div>
              )
            ) : hasIcons ? (
              <NavLink to='/' className='flex print:hidden' title='Search recipes'>
                <MagnifyingGlassIcon className={buttonClasses} />
              </NavLink>
            ) : (
              <span />
            )}
          </div>
        )}
        {children}
      </div>
    </section>
  );
}
