import React, { useEffect, useRef, useState } from 'react';
import {fetchCollections, fetchDailyAIRecipes, fetchRecipes} from '../../util/http';
import { useQuery } from '@tanstack/react-query';
import RecipesSkeleton from '../UI/RecipesSkeleton';
import ErrorBlock from '../UI/ErrorBlock';
import RecipeCard from './RecipeCard';
import Section from '../UI/Section';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Recipe } from '../../types';
import Tabs from '../UI/Tabs';
import LoadingIndicator from '../UI/LoadingIndicator';
import { Collection } from '../../types/collection';
import CollectionCard from '../Collections/CollectionCard';

export default function SearchRecipes() {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>();
  const [selectedCategory, setSelectedCategory] = useState('All categories');
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') || 'all-recipes';

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['recipes', { searchTerm, category: selectedCategory, max: 99 }],
    queryFn: ({ signal }) =>
      fetchRecipes({
        signal,
        searchTerm,
        category: selectedCategory.toLowerCase(),
        max: 99,
      }),
  });

  const {
    data: dailyAIRecipesData,
    isPending: isPendingDailyAIRecipes,
    isError: isErrordailyAIRecipes,
    error: errordailyAIRecipes,
  } = useQuery({
    queryKey: ['daily-ai-recipes'],
    queryFn: ({ signal }) => fetchDailyAIRecipes({ signal, max: 99 }),
  });

  const {
    data: collectionsData,
    isPending: isPendingCollections,
    isError: isErrorCollections,
    error: errorCollections,
  } = useQuery({
    queryKey: ['collections'],
    queryFn: ({ signal }) => fetchCollections({ signal }),
  });

  let content: JSX.Element | null = null;

  if (isPendingCollections) {
    content = <LoadingIndicator />;
  }

  if (isErrorCollections) {
    content = (
      <ErrorBlock
        title="An error occurred"
        message={errorCollections?.message || 'Failed to fetch user collections.'}
      />
    );
  }

  // 🔹 Filter recipes based on the active tab
  let filteredRecipes = data || [];
  let filteredDailyAIRecipes = dailyAIRecipesData || [];
  let filteredCollections = collectionsData || [];

  if (activeTab === 'ai-creations') {
    filteredRecipes = filteredRecipes.filter((recipe: any) => recipe.ai);
  } else if (activeTab === 'ai-daily-chef') {
    filteredDailyAIRecipes = filteredDailyAIRecipes.filter((recipe: any) => recipe.image !== null);
  } else if (activeTab === 'shared') {
    filteredRecipes = filteredRecipes.filter((recipe: any) => recipe.isSharedWithMe);
  } else if (activeTab === 'collections') {
    filteredCollections = collectionsData;
  }

  // 🔹 Define tabs
  const tabs = [
    { label: 'All Recipes', value: 'all-recipes', count: data?.length },
    { label: 'AI Daily Chef', value: 'ai-daily-chef', count: dailyAIRecipesData?.filter((r: any) => r.image !== null)?.length },
    { label: 'AI Creations', value: 'ai-creations', count: data?.filter((r: any) => r.ai)?.length },
    { label: 'Collections', value: 'collections', count: filteredCollections?.length },
  ];

  if (isPending || isPendingDailyAIRecipes) {
    content = <RecipesSkeleton />;
  }

  if (isError) {
    content = <ErrorBlock title="An error occurred" message={error?.message || 'Failed to fetch recipes.'} />;
  }

  // Recipes
  if (data && filteredRecipes) {
    filteredRecipes.sort((a: any, b: any) => b.createdAt?._seconds - a.createdAt?._seconds);

    content = (
      <div className="flex flex-wrap justify-center text-gray-800">
        {filteredRecipes.map((recipe: Recipe) => {
          const user = recipe.user;

          return <RecipeCard user={user} recipe={recipe} key={recipe.id} />;
        })}
      </div>
    );
  }

  // Daily AI Recipes
  if (activeTab === 'ai-daily-chef') {
    if (dailyAIRecipesData && filteredDailyAIRecipes) {
      filteredDailyAIRecipes.sort((a: any, b: any) => b.createdAt?._seconds - a.createdAt?._seconds);

      content = (
        <div className="flex flex-wrap justify-center text-gray-800">
          {filteredDailyAIRecipes.map((recipe: Recipe) => {
            return <RecipeCard recipe={recipe} key={recipe.id} path="recipes/ai-daily-recipes" />;
          })}
        </div>
      );
    }
  }

  // Collections
  if (activeTab === 'collections' && collectionsData) {
    content =
      filteredCollections.length === 0 ? (
        <div className="max-w-lg m-auto text-center">No collections created yet.</div>
      ) : (
        <div className="flex lg:px-12 gap-4 m-2 mt-6 flex-wrap justify-center text-gray-800">
          {filteredCollections.map((collection: Collection) => (
            <CollectionCard key={collection.id} collection={collection} />
          ))}
        </div>
      );
  }

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Stop the event propagation to prevent it from reaching the document-level click event listener
    event.stopPropagation();
    toggleDropdown();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownOpen(false);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setDropdownOpen(false);
    }
  };

  const handleCategorySelect = (category: any) => {
    setSelectedCategory(category);
    setDropdownOpen(false);
  };

  const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        setSearchTerm(event.target.value);
      }, 500)
    );
  };

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    const searchTerm = event.target.querySelector('input').value;

    navigate(`/recipes?category=${selectedCategory.toLowerCase()}&search=${searchTerm}`);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      {/* @ts-ignore */}
      {/*<Helmet>*/}
      {/*  <title>Bokkah – Recipes for all</title>*/}
      {/*  <meta property='og:title' content='Bokkah – Recipes for all' />*/}
      {/*  <meta*/}
      {/*    property='og:description'*/}
      {/*    content='Share your recipes with other people, get inspired and new ideas to cook.'*/}
      {/*  />*/}
      {/*</Helmet>*/}
      <h1 className="text-center text-3xl md:text-4xl lg:text-5xl xl:text-6xl mt-12 md:mt-24 xl:mt-52 font-bold md:mb-4 xl:mb-12 p-8 text-gray-700">
        What would you like
        <br />
        to cook today?
      </h1>
      <section className="max-w-2xl m-auto pb-4 mb-12 md:mb-24 xl:mb-52">
        <form onSubmit={handleFormSubmit} className="shadow-lg rounded-xl mx-2 sm:mx-0 bg-gray-50">
          <div className="flex relative">
            <label htmlFor="search-dropdown" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
              Select category
            </label>
            <button
              id="dropdown-button"
              onClick={handleButtonClick}
              className="sm:min-w-44 flex-shrink-0 inline-flex justify-between items-center p-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus-visible:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
              type="button"
            >
              {selectedCategory}{' '}
              <svg
                className="w-2.5 h-2.5 ms-2.5 transition-transform "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
                style={{
                  transform: `rotate(${isDropdownOpen ? '180' : '0'}deg)`,
                }}
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>
            <div
              ref={dropdownRef}
              id="dropdown"
              className={`${
                isDropdownOpen ? '' : 'hidden'
              } z-20 absolute top-16 bg-white divide-y divide-gray-100 rounded-lg shadow-xl border border-gray-300 w-44 dark:bg-gray-700`}
            >
              <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button">
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => handleCategorySelect('All categories')}
                  >
                    All categories
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => handleCategorySelect('Breakfast')}
                  >
                    Breakfast
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => handleCategorySelect('Desserts')}
                  >
                    Desserts
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => handleCategorySelect('Drinks')}
                  >
                    Drinks
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => handleCategorySelect('Fish')}
                  >
                    Fish
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => handleCategorySelect('Meals')}
                  >
                    Meals
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => handleCategorySelect('Soups')}
                  >
                    Soups
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => handleCategorySelect('Vegetarian')}
                  >
                    Vegetarian
                  </button>
                </li>
              </ul>
            </div>
            <div className="relative w-full">
              <input
                type="search"
                id="search-dropdown"
                className="block p-4 w-full text-sm text-gray-900 bg-gray-50  rounded-se-lg rounded-ee-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-gray-500 focus:border-gray-500 dark:bg-gray-700 dark:border-s-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-gray-500"
                placeholder="Search Smoothies, Meals, Vegeratian, Desserts Recipes..."
                onChange={handleSearchTermChange}
                // required
              />
              <button
                type="submit"
                className="absolute top-0 end-0 p-4 text-sm font-medium h-full rounded-e-lg text-gray-900 bg-gray-100 border border-gray-300 hover:bg-gray-200 focus-visible:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            </div>
          </div>
        </form>
      </section>

      <Section
        // title={
        //   searchTerm && data?.length > 0
        //     ? `Recipes Found for: "${searchTerm}"`
        //     : searchTerm && data?.length === 0
        //     ? 'No Recipes Found'
        //     : selectedCategory && data?.length > 0
        //     ? selectedCategory !== 'All categories'
        //       ? `Recipes Found in "${selectedCategory}"`
        //       : 'Latest Recipes'
        //     : selectedCategory && data?.length === 0
        //     ? `No Recipes Added Yet in "${selectedCategory}"`
        //     : 'Latest Recipes'
        // }
        hasRecipes
        hasIcons={false}
      >
        <Tabs tabs={tabs} defaultTab="all-recipes" />
        <div className="mt-24 sm:mt-4 min-h-[calc(100dvh-11.5rem)]">{content}</div>
      </Section>
    </>
  );
}
