import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { User } from '../../types';

export default function UsersRoll({
  users,
  isSmall = false,
}: {
  users: User[];
  isSmall?: boolean;
}) {
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const maxUsersToShow = 6;

  return (
    <figure className='flex flex-inline'>
      {users?.slice(0, maxUsersToShow).map((user, index) => {
        return (
          <div
            key={index}
            className='-mr-3 shadow-sm'
            style={{
              zIndex:
                hoverIndex === index
                  ? maxUsersToShow + 10
                  : maxUsersToShow - index,
              transition: 'z-index 0.3s',
            }}
            onMouseOver={() => setHoverIndex(index)}
            onMouseOut={() => setHoverIndex(null)}
          >
            <NavLink to={`/users/${user.username}/`} title={user.name} className="flex border border-white overflow-hidden rounded-full transition outline-none focus:ring-4">
              <img
                src={user?.image}
                alt={`${user.name}'s avatar`}
                title={user.name}
                className={`bg-skeleton ${isSmall ? 'w-8 h-8' : 'w-12 h-12'} `}
                loading="lazy"
              />
            </NavLink>
          </div>
        );
      })}
      {users?.length > maxUsersToShow && (
        <div className='overflow-hidden rounded-full -mr-3 border  shadow-sm scale-75'>
          <div
            className={`flex justify-center items-center  text-gray-600 ${
              isSmall ? 'w-8 h-8' : 'w-12 h-12'
            } `}
          >
            <p className='text-md font-bold tracking-tight p-1'>
              +{users.length - maxUsersToShow}
            </p>
          </div>
        </div>
      )}
    </figure>
  );
}
