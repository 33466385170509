import React from 'react';
import { NavLink } from 'react-router-dom';
import CollectionCover from './CollectionCover';
import UsersRoll from '../UI/UsersRoll';
import Button from '../UI/Button';
import { useUser } from '../../store/UserContext';
import { PhotoIcon, PencilIcon } from '@heroicons/react/24/outline';
import { Collection } from '../../types/collection';

export default function CollectionCard({ collection }: { collection: Collection }) {
  const { user } = useUser();

  const editCapabilities =
    collection?.created_by.uid === user?.id || collection?.users?.some((invitedUser) => invitedUser?.uid === user?.id);

  return (
    <article
      key={collection?.name}
      className="w-full sm:w-[24rem] flex flex-col gap-4 items-center bg-white shadow-lg hover:shadow-xl transition-shadow rounded-lg"
    >
      <NavLink to={`/collections/${collection?.id}/`} className="w-full transition outline-none focus:ring-4 rounded-lg">
        {collection?.recipes?.length > 0 ? (
          <CollectionCover collection={collection} />
        ) : (
          <div className="bg-gray-100 opacity-90 object-cover rounded-md w-full h-[calc(20rem+0.5rem)] flex flex-col gap-4 justify-center items-center">
            <PhotoIcon className="size-12 text-gray-300" />
            <p className="text-sm text-gray-400">No recipes</p>
          </div>
        )}
      </NavLink>

      <legend className="w-full flex flex-col h-full">
        <NavLink to={`/collections/${collection?.id}/`} className="mx-4 px-2 transition outline-none rounded-lg focus:ring-4 focus:ring-offset-4">
          <h2 className="font-bold py-2 text-balance">{collection?.name}</h2>
        </NavLink>
        <p className="px-6 hyphens-auto text-sm text-gray-600 text-balance text-ellipsis overflow-hidden line-clamp-6 sm:line-clamp-3">
          {collection?.description ? (
            collection?.description
          ) : (
            <span className="italic text-gray-400">No description added yet.</span>
          )}
        </p>
        <div className="mt-auto p-6 flex gap-2 items-center justify-between text-sm text-gray-600 me-3">
          {editCapabilities ? (
            <Button isPrimary to={`/collections/${collection?.id}/`}>
              <div className="flex gap-2 items-center">
                <PencilIcon className="size-4" /> <span>Edit collection</span>
              </div>
            </Button>
          ) : (
            <Button to={`/collections/${collection?.id}/`} className="flex gap-2 items-center">
              Browse collection
            </Button>
          )}
          <UsersRoll users={collection?.users} isSmall />
        </div>
      </legend>
    </article>
  );
}
