import { useSearchParams } from 'react-router-dom';
import {useCallback, useEffect, useRef, useState} from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

interface Tab {
  label: string;
  value: string;
  count?: number;
}

interface TabsProps {
  tabs: Tab[];
  defaultTab: string;
}

export default function Tabs({ tabs, defaultTab }: TabsProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') || defaultTab;
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const tabRefs = useRef<{ [key: string]: HTMLLIElement | null }>({});
  const contentRef = useRef<HTMLDivElement | null>(null);

  function handleTabClick(tabValue: string) {
    searchParams.set('tab', tabValue);
    setSearchParams(searchParams);
  }

  function handleSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const newTab = event.target.value;
    searchParams.set('tab', newTab);
    setSearchParams(searchParams);
  }

  // ✅ Function to update indicator position
  const updateIndicator = useCallback(() => {
    requestAnimationFrame(() => {
      const activeTabElement = tabRefs.current[activeTab];
      if (activeTabElement) {
        const { offsetLeft, offsetWidth } = activeTabElement;
        setIndicatorStyle({ left: offsetLeft, width: offsetWidth });
      }
    });
  }, [activeTab]);

  // ✅ Scroll to top only when `tab` param is explicitly set
  useEffect(() => {
    if (searchParams.has('tab')) {
      if (contentRef.current) {
        const topOffset = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--scroll-offset')) || 0;
        const elementPosition = contentRef.current.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: elementPosition - topOffset, behavior: 'smooth' });
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }, [activeTab, searchParams]);



  // ✅ Update indicator when tab changes or on initial load
  useEffect(() => {
    updateIndicator();
  }, [activeTab, tabs, updateIndicator]);

  // ✅ Update on window resize to reposition correctly
  useEffect(() => {
    window.addEventListener('resize', updateIndicator);
    return () => window.removeEventListener('resize', updateIndicator);
  }, [updateIndicator]);

  useEffect(() => {
    const activeTabElement = tabRefs.current[activeTab];
    if (activeTabElement) {
      const { offsetLeft, offsetWidth } = activeTabElement;
      setIndicatorStyle({ left: offsetLeft, width: offsetWidth });
    }
  }, [activeTab, tabs]);

  return (
    <>
      {/* Attach ref to the top of the content area */}
      <div ref={contentRef} />

      <nav className="mx-2 sm:mx-0  -mb-20 sm:mb-0 border-b border-b-gray-200 sticky top-20 sm:top-0 z-10 bg-gray-50 shadow-lg rounded-lg">
      {/* Desktop */}
      <ul className="hidden sm:flex items-center justify-center gap-8 -mb-[1px]">
        {tabs.map(({ label, value, count }) => (
          <li key={value} ref={(el) => (tabRefs.current[value] = el)} className="relative">
            <button
              className={`pt-8 pb-6 flex justify-center items-center font-semibold transition outline-none focus-visible:ring-4 rounded-md focus-visible:ring-offset-4 ${
                activeTab === value ? 'text-gray-900' : 'text-gray-500'
              }`}
              onClick={() => handleTabClick(value)}
            >
              {label}
              {count !== undefined && (
                <span className="hidden lg:block bg-slate-200 text-xs px-2 py-1 tracking-wide rounded-full ml-3">{count}</span>
              )}
            </button>
          </li>
        ))}
      </ul>

      {/* Animated bottom border */}
      <div
        className="absolute bottom-0 h-[3px] bg-gray-900 rounded-t-lg transition duration-300"
        style={indicatorStyle}
      />

      {/* Mobile */}
      <div className="block sm:hidden relative">
        <select
          className="w-full p-4 border border-gray-300 bg-gray-50 rounded-lg appearance-none text-gray-600"
          aria-label="Select a tab"
          value={activeTab}
          onChange={handleSelectChange}
        >
          {tabs.map(({ label, value, count }) => (
            <option key={value} value={value}>
              {label} {count !== undefined ? `(${count})` : ''}
            </option>
          ))}
        </select>
        <ChevronDownIcon className="size-4 text-gray-500 absolute right-4 top-1/2 transform -translate-y-1/2" />
      </div>
    </nav>
    </>
  );
}
