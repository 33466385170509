import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PhotoIcon, TrashIcon } from '@heroicons/react/24/outline';
import CategorySelect from '../UI/CategorySelect';
import SwitchField from '../UI/SwitchField';
import { CATEGORIES } from '../../util/categories';

interface RecipeFormProps {
  inputData?: any;
  onSubmit: (data: any) => void;
  children: React.ReactNode;
  onImageChange: (file: any) => void;
  markAsDraft?: boolean;
}

export default function RecipeForm({
  inputData,
  onSubmit,
  children,
  onImageChange,
  markAsDraft = false,
}: RecipeFormProps) {
  const [image, setImage] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [steps, setSteps] = useState<string[]>([]);
  const [ingredients, setIngredients] = useState<string[]>([]);

  const labelClasses = 'block text-gray-800 font-bold mb-4 cursor-pointer';
  const inputClasses =
    'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-4 transition-all';

  const trashButtonClasses =
    'rounded-full shadow-transparent hover:shadow-lg size-10 p-2 -my-2 text-gray-700 hover:text-gray-900 transition-all outline-none focus:ring-4';

  const recipe = inputData;
  const [isDraft, setIsDraft] = useState<boolean>(recipe?.draft || false);

  // To Review usage needed
  console.log(steps, 'steps');
  console.log(ingredients, 'ingredients');

  useEffect(() => {
    setIsDraft(markAsDraft ? true : recipe?.draft);
  }, [markAsDraft, recipe]);

  const { register, setValue, getValues } = useForm({
    defaultValues: {
      title: recipe?.title || '',
      image: recipe?.image || null,
      ingredients: recipe?.ingredients || [],
      steps: recipe?.steps || [],
      category: recipe?.category.name?.toLowerCase() || '',
      country: recipe?.country || '',
      draft: markAsDraft ? true : recipe?.draft || false,
      source: recipe?.source || '',
    },
  });

  interface CategoryFieldData {
    name: string;
    id: number;
  }

  const [selected, setSelected] = useState<CategoryFieldData | null>(null);

  useEffect(() => {
    if (inputData?.category) {
      const categoryFromInput = CATEGORIES.find(
        (category) =>
          category.id === inputData.category.id ||
          category.name?.toLowerCase() === inputData.category?.name?.toLowerCase() ||
          1
      );

      setSelected(categoryFromInput || null); // Set the matched category or null
    } else {
      setSelected(CATEGORIES[3] || null);
    }
  }, [inputData]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setImageFile(file || null);

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setValue('image', imageUrl);
      setImage(imageUrl);
      onImageChange(file);
    }
  };

  const handleAddStep = () => {
    const currentSteps = getValues('steps') || [];
    setSteps([...currentSteps, '']);
    setValue('steps', [...currentSteps, '']);
  };

  const handleStepChange = (index: number, value: string) => {
    const currentSteps = getValues('steps') || [];
    currentSteps[index] = value;
    setValue('steps', currentSteps);
  };

  const handleRemoveStep = (index: number) => {
    const currentSteps = getValues('steps');
    const updatedSteps = currentSteps.filter((_: any, i: number) => i !== index);

    // Set the updated values directly using setValue
    setValue('steps', updatedSteps);
    setSteps(updatedSteps);
  };

  const handleAddIngredient = () => {
    const currentIngredients = getValues('ingredients') || [];
    setIngredients([...currentIngredients, '']);
    setValue('ingredients', [...currentIngredients, '']);
  };

  const handleIngredientChange = (index: number, value: string) => {
    const currentIngredients = getValues('ingredients') || [];
    currentIngredients[index] = value;
    setValue('ingredients', currentIngredients);
  };

  const handleRemoveIngredient = (index: number) => {
    const currentIngredients = getValues('ingredients');
    const updatedIngredients = currentIngredients.filter((_: any, i: number) => i !== index);

    // Set the updated values directly using setValue
    setValue('ingredients', updatedIngredients);
    setIngredients(updatedIngredients);
  };

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!selected?.id) {
      alert('Please select a category.');
      return;
    }

    setValue('category', selected.id);

    const initialImage = recipe?.image === 'null' ? null : recipe?.image; // Convert "null" to null
    const formData = new FormData(event.target as HTMLFormElement);

    formData.append('draft', getValues('draft'));

    if (imageFile) {
      formData.append('image', imageFile); // Append the actual file if provided
      onImageChange(imageFile);
    } else if (!isDraft && !initialImage) {
      formData.append('image', ''); // Leave it empty for new recipes without an image
      onImageChange(null);
    } else if (initialImage) {
      formData.append('image', initialImage); // Use the existing image if editing
    } else {
      formData.delete('image'); // Omit the field completely if not applicable
      onImageChange(null);
    }

    if (!isDraft) {
      if (getValues('ingredients').length === 0) {
        alert('Ingredients missing');
        handleAddIngredient();
        return;
      }

      if (getValues('steps').length === 0) {
        alert('Steps missing');
        handleAddStep();
        return;
      }
    }

    formData.append('category', selected.id.toString());

    let data = Object.fromEntries(formData);

    onSubmit({ ...data });
  }

  return (
    <form className="px-6 py-8 sm:p-8 bg-white shadow-xl rounded-xl" onSubmit={handleSubmit}>
      <div className="mb-8">
        <label htmlFor="title" className={labelClasses}>
          Title
        </label>
        <input
          id="title"
          {...register('title')}
          placeholder="Your recipe title"
          className={inputClasses}
          required
          autoComplete="off"
          autoFocus={recipe?.title === undefined}
        />
      </div>
      <div className="flex flex-col gap-4 mb-10">
        <div>
          <label htmlFor="image" className={labelClasses}>
            {recipe?.image !== undefined || image ? 'Image' : 'Image Upload'}
          </label>
          <div className="w-full sm:h-[20rem]">
            <label htmlFor="image" className="cursor-pointer">
              {recipe?.image || image ? (
                <img
                  src={getValues('image') || recipe?.image || image}
                  alt={recipe?.title}
                  className="rounded-lg object-cover w-full h-[20rem] text-gray-200 bg-skeleton border-2 border-white shadow-lg"
                />
              ) : (
                <div className="rounded-lg object-cover w-full h-[20rem] bg-gray-100 flex flex-col gap-4 justify-center items-center">
                  <PhotoIcon className="size-12 text-gray-300" />
                  <p className="text-sm text-gray-400">No image added yet.</p>
                </div>
              )}
            </label>
          </div>
        </div>
        <div className="-mb-12">
          <input
            id="image"
            name="image"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            required={!isDraft && !recipe?.image}
            className="text-sm relative -top-[3.5rem] left-4 cursor-pointer p-2 -m-2 w-[calc(100%-0.75rem)] appearance-none rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-4 transition-all"
          />
        </div>
      </div>
      <div className="mb-8">
        <label htmlFor="category" className={labelClasses}>
          Category
        </label>
        <input {...register('category')} value={selected?.id} required hidden />
        <CategorySelect id="category" name="category" selected={selected} setSelected={setSelected} />
      </div>
      <div className="mb-8">
        <label className={labelClasses}>Ingredients</label>
        {getValues('ingredients')?.map((ingredient: string, index: number) => (
          <div key={index} className="mb-4 flex justify-between items-center gap-4">
            <input
              {...register(`ingredients.${index}`)}
              placeholder={`Ingredient ${index + 1}`}
              className={inputClasses}
              onChange={(e) => handleIngredientChange(index, e.target.value)}
              required={!isDraft}
              autoComplete="off"
              autoFocus={ingredients[index] === ''}
            />
            <button
              type="button"
              className={trashButtonClasses}
              onClick={() => handleRemoveIngredient(index)}
              title="Click to remove this ingredient"
            >
              <TrashIcon />
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={handleAddIngredient}
          className="border px-4 py-2 outline-none focus:ring-4 rounded"
        >
          + Add Ingredient
        </button>
      </div>
      <div className="mb-8">
        <label className={labelClasses}>Steps</label>
        {getValues('steps')?.map((step: string, index: number) => (
          <div key={index} className="mb-4 flex justify-between items-center gap-4">
            <span>{index + 1}</span>
            <textarea
              {...register(`steps.${index}`)}
              placeholder={`Step ${index + 1}`}
              className={inputClasses}
              onChange={(e) => handleStepChange(index, e.target.value)}
              rows={3}
              required={!isDraft}
              autoFocus={steps[index] === ''}
            />
            <button
              type="button"
              className={trashButtonClasses}
              onClick={() => handleRemoveStep(index)}
              title="Click to remove this step"
            >
              <TrashIcon />
            </button>
          </div>
        ))}
        <button type="button" onClick={handleAddStep} className="border px-4 py-2 outline-none focus:ring-4 rounded">
          + Add Step
        </button>
      </div>

      <hr />
      <div className="mb-8 mt-8">
        <label htmlFor="draft" className={labelClasses}>
          <div className="flex gap-4 justify-between items-center">
            <input
              id="draft"
              {...register('draft')}
              type="checkbox"
              className="sr-only peer"
              defaultChecked={getValues('draft')}
              onClick={() => setIsDraft(!isDraft)}
            />
            <p>Mark recipe as "draft"?</p>
            <SwitchField />
          </div>
        </label>
      </div>
      {recipe?.source && (
        <div className="mb-8">
          <label htmlFor="source" className={labelClasses}>
            Recipe source
          </label>
          <input
            id="source"
            {...register('source')}
            value={getValues('source')}
            placeholder="Recipe source"
            className={inputClasses + ' cursor-not-allowed text-ellipsis overflow-hidden line-clamp-1'}
            disabled
          />
        </div>
      )}

      <div className="pt-4 min-h-[4rem] flex items-center">{children}</div>
    </form>
  );
}
