import { QueryClient } from '@tanstack/react-query';
import Resizer from "react-image-file-resizer";
import { getStorage, ref, uploadBytes, getDownloadURL, updateMetadata } from 'firebase/storage';
import { storage } from './firebase';
import { supabase } from "./supabaseClient";
import { getAuth, signInWithCustomToken } from 'firebase/auth';

export const imagesRef = ref(storage, 'images');

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1 * 5 * 60 * 1000, // 5 mins
      gcTime: 1 * 60 * 60 * 1000,   // 1 hours
    },
  },
});

const API_URL = process.env.NODE_ENV === 'production'
  ? 'https://europe-west1-react-recipes-app-bd56e.cloudfunctions.net/api'
  // : 'http://192.168.1.12:3000';
  : 'http://localhost:3000';

export async function fetchRecipes({ signal, searchTerm, category, max }) {
  let url = `${API_URL}/recipes`;

  if (searchTerm && max) {
    url += '?search=' + searchTerm + '&max=' + max;
  } else if (searchTerm && !category) {
    url += '?search=' + searchTerm;
  } else if (category && !searchTerm) {
    url += '?category=' + category;
  } else if (searchTerm && category) {
    url += '?category=' + category + '&search=' + searchTerm;
  } else if (max) {
    url += '?max=' + max;
  }

  const response = await fetch(url, { signal: signal });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the recipes');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { recipes } = await response.json();

  return recipes;
}

export async function createNewRecipe(recipeData) {
  const response = await fetch(`${API_URL}/recipes`, {
    method: 'POST',
    body: JSON.stringify(recipeData),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const errorResponse = await response.json();

    let errorMessage = 'An error occurred while creating the recipe. Please try again!';

    if (response.status === 429) {
      errorMessage = 'You are creating too many recipes! Please slow down and try again later.';
    } else if (response.status === 400) {
      errorMessage = errorResponse?.error || 'Invalid recipe data provided. Please check your inputs and try again.';
    } else if (response.status === 403) {
      errorMessage = 'You are not authorized to create new recipes. Please log in or contact support.';
    } else if (response.status >= 500) {
      errorMessage = 'Server is currently experiencing issues. Please try again later.';
    }

    const error = new Error(errorMessage);
    error.code = response.status;
    error.info = errorResponse;
    throw error;
  }

  const { recipe } = await response.json();

  return recipe;
}

export async function sendRecipeUrl(url) {
  const response = await fetch(`${API_URL}/ai/get-recipe`, {
    method: 'POST',
    body: JSON.stringify(url),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error('An error occurred while creating the recipe');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { recipe } = await response.json();

  return recipe;
}

export async function generateRecipeFromIngredients({ ingredients, kitchen_type, inspired_by }) {
  const response = await fetch(`${API_URL}/ai/generate-recipes`, {
    method: 'POST',
    body: JSON.stringify({ ingredients, kitchen_type, inspired_by }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const errorResponse = await response.json();

    let errorMessage = 'An error occurred while generating the recipes... review your ingredients and try again!';

    // Customize error message for specific status codes
    if (response.status === 429) {
      errorMessage = 'You are making too many AI requests! Please wait a while before trying again.';
    } else if (response.status >= 500) {
      errorMessage = 'Server is currently unavailable. Please try again later.';
    }

    const error = new Error(errorMessage);
    error.code = response.status;
    error.info = errorResponse;
    throw error;
  }

  const { recipes } = await response.json();

  return recipes;
}

export async function uploadImage(file, recipeId) {
  try {
    // Step 1: Get Supabase Token & User Data
    const { data: { session }, error } = await supabase.auth.getSession();
    if (error || !session) {
      throw new Error("User not authenticated.");
    }

    const supabaseToken = session.access_token;
    const user = session.user; // 🔥 Get Supabase User
    const userId = user.id; // 🔥 Use UID instead of username

    // Step 2: Exchange Supabase Token for Firebase Token
    const firebaseTokenResponse = await fetch(`${API_URL}/users/getFirebaseToken`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ supabaseToken }),
    });

    if (!firebaseTokenResponse.ok) {
      throw new Error("Failed to retrieve Firebase token");
    }

    const { firebaseToken } = await firebaseTokenResponse.json();

    // Step 3: Sign in Firebase using the token
    const auth = getAuth();
    await signInWithCustomToken(auth, firebaseToken);

    // Step 4: Define storage reference path (🔥 Use user UID instead of username)
    const storage = getStorage();
    let userImagesRef;
    if (recipeId) {
      userImagesRef = ref(storage, `users/${userId}/rcp__${recipeId}__${file.name}`);
    } else {
      userImagesRef = ref(storage, `users/${userId}/${file.name}`);
    }

    // Step 5: Upload file to Firebase Storage
    await uploadBytes(userImagesRef, file);

    // Step 6: Set Cache-Control metadata (optional)
    await updateMetadata(userImagesRef, {
      cacheControl: 'public, max-age=31536000'
    });

    // Step 7: Get the image URL
    const imageUrl = await getDownloadURL(userImagesRef);
    return imageUrl;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
}


// Function to resize the image
const resizeImage = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1280, // Max width
      1280, // Max height
      'JPEG',
      80, // Quality (0-100)
      0, // Rotation
      (uri) => {
        resolve(uri);
      },
      'blob' // Output type ('blob' or 'base64')
    );
  });

export async function fetchSelectableImages({ signal }) {
  const response = await fetch(`${API_URL}/recipes/images`, {
    signal,
  });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the images');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { images } = await response.json();

  return images;
}

export async function fetchRecipe({ id, signal }) {
  const response = await fetch(`${API_URL}/recipes/${id}`, {
    signal,
  });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the recipe');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { recipe } = await response.json();

  return recipe;
}

export async function deleteRecipe({ id }) {
  const response = await fetch(`${API_URL}/recipes/${id}`, {
    method: 'DELETE',
  });

  if (!response.ok) {
    const error = new Error('An error occurred while deleting the recipe');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}

export async function updateRecipe({ id, recipe }) {
  const response = await fetch(`${API_URL}/recipes/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ recipe }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error('An error occurred while updating the recipe');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}

export async function fetchCategories({ signal }) {
  let url = `${API_URL}/categories`;

  const response = await fetch(url, { signal: signal });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching categories');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { categories } = await response.json();

  return categories;
}

// Users

export async function fetchUsers({ signal, searchTerm, max }) {
  let url = `${API_URL}/users`;

  if (searchTerm && max) {
    url += '?search=' + searchTerm + '&max=' + max;
  } else if (searchTerm) {
    url += '?search=' + searchTerm;
  } else if (max) {
    url += '?max=' + max;
  }

  const response = await fetch(url, { signal: signal });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the users');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { users } = await response.json();

  return users;
}

export async function fetchUser({ username, signal }) {
  const response = await fetch(`${API_URL}/users/${username}`, {
    signal,
  });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the user');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { user } = await response.json();

  return user;
}

export async function fetchUserRecipes({ uid, signal }) {
  const response = await fetch(`${API_URL}/users/get/${uid}/recipes`, {
    signal,
  });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the user recipes');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { recipes } = await response.json();

  return recipes;
}

export async function fetchUserRecipesByUsername({ username, signal }) {
  const response = await fetch(`${API_URL}/users/${username}/recipes`, {
    signal,
  });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the user recipes');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { recipes } = await response.json();

  return recipes;
}

export async function fetchUserByUid({ uid, signal }) {
  if (uid === undefined) return;

  const response = await fetch(`${API_URL}/users/get/${uid}`, {
    signal,
  });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the user');
    error.code = response.status;
    error.info = await response.json();
    // throw error;
    let user = null;
    return user;
  }

  const { user } = await response.json();

  return user;
}

export async function fetchUserFavoriteRecipes({ username, signal }) {
  const response = await fetch(`${API_URL}/users/${username}/favorites`, { signal });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the user favorite recipes');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { recipes } = await response.json();

  return recipes;
}

export async function fetchUserCollections({ uid, signal }) {
  const response = await fetch(`${API_URL}/users/get/${uid}/collections`, {
    signal,
  });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the user collections');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  if (response.status === 204) {
    const collections = []

    return collections;
  }

  const { collections } = await response.json();

  return collections;
}

export async function AddToUserCollections([username, collection, recipeId]) {
  try {
    const response = await fetch(`${API_URL}/users/${username}/collections/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        collection: collection,
        recipeId,
      }),
    });

    if (!response.ok) {
      alert(`Failed to add recipe to collection. Please try again.`);
    }
  } catch (error) {
    console.error('Error adding recipe to collection:', error);
    alert(`An error occurred. Please try again.`);
  }
}

export async function RemoveFromUserCollections([username, collectionId, recipeId]) {
  try {
    const response = await fetch(`${API_URL}/users/${username}/collections/${collectionId}/recipes/${recipeId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const error = new Error('Failed to remove recipe from collection. Please try again.');
      error.code = response.status;
      error.info = await response.json();
      throw error;
    }

    return response.json();
  } catch (error) {
    console.error('Error removing recipe from collection:', error);
    alert(`An error occurred. Please try again.`);
  }
}

export async function fetchUserCollectionsByUsername({ username, signal }) {
  const response = await fetch(`${API_URL}/users/${username}/collections/`, {
    signal
  });


  if (!response.ok) {
    const error = new Error('An error occurred while fetching the user collections');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { collections } = await response.json();

  return collections;
}

export async function updateUser({ username, user }) {
  const response = await fetch(`${API_URL}/users/${username}`, {
    method: 'PUT',
    body: JSON.stringify({ user }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error('An error occurred while updating the user');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}

export async function createNewUser(userData) {
  const response = await fetch(`${API_URL}/users/create`, {
    method: 'POST',
    body: JSON.stringify(userData),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error('An error occurred while creating the user');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { user } = await response.json();

  return user;
}

export async function deactivateUser({ username }) {
  const response = await fetch(`${API_URL}/users/${username}?deactivate=true`, {
    method: 'PUT',
  });

  if (!response.ok) {
    const error = new Error('An error occurred while deactivating the user');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}

export async function activateUser({ username }) {
  const response = await fetch(`${API_URL}/users/${username}?activate=true`, {
    method: 'PUT',
  });

  if (!response.ok) {
    const error = new Error('An error occurred while activating the user');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}

export async function fetchUserFavorites({ username, signal }) {
  const response = await fetch(`${API_URL}/users/${username}/favorites`, {
    signal,
  });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the user recipes');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { recipes } = await response.json();

  return recipes;
}

export async function fetchUserFriends({ username, signal, max }) {
  let url = `${API_URL}/users/${username}/friends`;

  if (max) {
    url += '?max=' + max;
  }

  const response = await fetch(url, {
    signal,
  });

  if (!response.ok) {
    const error = new Error(`An error occurred while fetching the ${username} friends`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { friends } = await response.json();

  return friends;
}

export async function fetchUserFriendsRecipes({ username, signal, max }) {
  let url = `${API_URL}/users/${username}/friends/recipes`;

  if (max) {
    url += '?max=' + max;
  }

  const response = await fetch(url, {
    signal,
  });

  if (!response.ok) {
    const error = new Error(`An error occurred while fetching the ${username} friends`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { recipes } = await response.json();

  return recipes;
}

export async function fetchUserFriendsCollections({ username, signal, max }) {
  let url = `${API_URL}/users/${username}/friends/collections`;

  if (max) {
    url += '?max=' + max;
  }

  const response = await fetch(url, {
    signal,
  });

  if (!response.ok) {
    const error = new Error(`An error occurred while fetching the ${username} collections`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { collections } = await response.json();

  return collections;
}

export async function fetchNotifications({ uid, signal, max }) {
  let url = `${API_URL}/notifications/${uid}`;

  if (max) {
    url += '?max=' + max;
  }

  const response = await fetch(url, {
    signal,
  });

  if (!response.ok) {
    const error = new Error(`An error occurred while fetching the ${uid} notifications`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { notifications } = await response.json();

  return notifications;
}

export async function fetchUserDashboard({ uid, signal }) {
  let url = `${API_URL}/dashboard/${uid}`;

  const response = await fetch(url, {
    signal,
  });

  if (!response.ok) {
    const error = new Error(`An error occurred while fetching the ${uid} dashboard`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { dashboard } = await response.json();

  return dashboard;
}

export async function fetchUserDashboardNotifications({ uid, signal, max }) {
  let url = `${API_URL}/dashboard/${uid}/notifications`;

  if (max) {
    url += '?max=' + max;
  }

  const response = await fetch(url, {
    signal,
  });

  if (!response.ok) {
    const error = new Error(`An error occurred while fetching the ${uid} notifications`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { notifications } = await response.json();

  return notifications;
}

export async function updateNotificationStatus({ uid, notificationId, action }) {
  const url = `${API_URL}/dashboard/${uid}/notifications/${notificationId}`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ action }),
  });

  if (!response.ok) {
    const error = new Error(`An error occurred while updating notification status`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

export async function markNotificationAsRead({ uid, notificationId }) {
  const url = `${API_URL}/dashboard/${uid}/notifications/${notificationId}/mark-as-read`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error(`An error occurred while marking notification as read`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

export async function addToFriends({ uid, notifier }) {
  const url = `${API_URL}/notifications/${uid}/add-to-friends`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ uid, notifier }),
  });

  if (!response.ok) {
    const error = new Error(`An error occurred while adding to friends`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

export async function removeFromFriends({ uid, notifier }) {
  const url = `${API_URL}/notifications/${uid}/remove-from-friends`;

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ uid, notifier }),
  });

  if (!response.ok) {
    const error = new Error(`An error occurred while removing from friends`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

export async function inviteToCollection({ collectionId, uid, invitedUsersIds }) {
  const url = `${API_URL}/notifications/${collectionId}/invite-to-collection`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ uid, invitedUsersIds }),
  });

  if (!response.ok) {
    const error = new Error(`An error occurred while inviting friends to collection`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}
export async function askToJoinCollection({ collectionId, uid, creatorUid }) {
  const url = `${API_URL}/notifications/${collectionId}/ask-to-join-collection`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ uid, creatorUid }),
  });

  if (!response.ok) {
    let errorMessage = 'An error occurred while asking to join collection';

    try {
      const errorResponse = await response.json();
      console.error("🔴 Error Response:", errorResponse); // Debug log

      if (errorResponse?.message) {
        errorMessage = errorResponse.message; // ✅ Adjusted to correctly retrieve the message
      } else if (errorResponse?.error) {
        errorMessage = errorResponse.error;
      }
    } catch (error) {
      console.error("🔴 Failed to parse JSON error response:", error);
    }

    throw new Error(errorMessage.message);
  }

  return await response.json();
}


export async function fetchCollections({ signal }) {
  const url = `${API_URL}/collections`;

  const response = await fetch(url, { signal: signal });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching collections');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { collections } = await response.json();

  return collections;
}

export async function fetchCollection({ signal, collectionId }) {
  const url = `${API_URL}/collections/${collectionId}`;

  const response = await fetch(url, { signal: signal });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching collection');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { collection } = await response.json();

  return collection;
}

export async function createNewCollection(collectionData) {
  const response = await fetch(`${API_URL}/collections`, {
    method: 'POST',
    body: JSON.stringify(collectionData),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error('An error occurred while creating the collection');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { collection } = await response.json();

  return collection;
}

export async function updateCollection({ id, collection, user }) {
  const response = await fetch(`${API_URL}/collections/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ collection, updateby: user }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error('An error occurred while updating the recipe');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}

export async function deleteCollection({ collectionId, username }) {
  const response = await fetch(`${API_URL}/collections/${collectionId}`, {
    method: 'DELETE',
    body: JSON.stringify({ username }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error('An error occurred while deleting the collection');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}

export async function followEntity({ uid, entityId, entityType }) {
  const response = await fetch(`${API_URL}/follow`, {
    method: 'POST',
    body: JSON.stringify({ uid, entityId, entityType }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error(`An error occurred while following ${entityType}`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}

export async function unfollowEntity({ uid, entityId, entityType }) {
  const response = await fetch(`${API_URL}/unfollow`, {
    method: 'POST',
    body: JSON.stringify({ uid, entityId, entityType }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error(`An error occurred while unfollowing ${entityType}`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}

// TODO: Review usage
export async function fetchFollowedEntities({ signal, uid, entityType }) {
  const url = `${API_URL}/followed?userId=${uid}&entityType=${entityType}`;

  const response = await fetch(url, { signal })

  if (!response.ok) {
    const error = new Error(`An error occurred while unfollowing ${entityType}`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}


// Invites
export async function fetchInviteCount({ uid, signal }) {
  const url = `${API_URL}/invites/count/${uid}`;

  const response = await fetch(url, { signal })

  if (!response.ok) {
    const error = new Error(`An error occurred while fetching invites`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { count } = await response.json();

  return count;
}

export async function sendInvite({ uid, email }) {
  const response = await fetch(`${API_URL}/invites/send`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user_uid: uid, email }),
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    const errorMessage = errorResponse.error || 'An error occurred while sending the invite';

    // ✅ Check for duplicate invite error & provide a user-friendly message
    if (errorMessage.includes('duplicate key value violates unique constraint')) {
      throw new Error('This email address has already been invited.');
    }

    throw new Error(errorMessage);
  }

  return await response.json(); // Only return JSON if the request is successful
}


// Daily AI Recipes
export async function fetchDailyAIRecipes({ signal, max }) {
  let url = `${API_URL}/ai/daily-recipes/`;

  if (max) {
    url += '?max=' + max;
  }

  const response = await fetch(url, { signal: signal });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the daily AI recipes');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { recipes } = await response.json();

  return recipes;
}

export async function fetchDailyAIRecipe({ id, signal }) {
  const response = await fetch(`${API_URL}/ai/daily-recipes/${id}`, {
    signal,
  });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the daily AI recipe');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { recipe } = await response.json();

  return recipe;
}
