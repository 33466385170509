import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ErrorBlock from '../UI/ErrorBlock';
import { createNewCollection, queryClient } from '../../util/http.js';

import Button from '../UI/Button';
import CollectionForm from './CollectionForm';
import { useUser } from '../../store/UserContext';
import { Collection } from '../../types/collection';
import Section from "../UI/Section";

export default function NewCollection() {
  const navigate = useNavigate();
  const { user } = useUser();
  const [collectionId, setCollectionId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // ✅ Track error state

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: createNewCollection,
    onSuccess: (data) => {
      setCollectionId(data.id);
      queryClient.invalidateQueries({ queryKey: ['collections'] });
      queryClient.invalidateQueries({
        queryKey: ['user-collections', user.username],
      });
    },
    onError: (error: any) => {
      setIsSubmitting(false);
      console.error("Detailed error:", error?.message);
      setErrorMessage("Your collection contains inappropriate content. Please review and try again.");
    },
  });

  function handleSubmit(formData: Collection) {
    setIsSubmitting(true);
    setErrorMessage(null);

    const collectionData = {
      uid: user.uid,
      username: user.username,
      collection: formData.name,
      description: formData.description,
    };

    const handleMutation = (data: any) => {
      mutate(data, {
        onSuccess: () => {
          setIsSubmitting(false);
        },
        onError: (error: any) => {
          setIsSubmitting(false);
        },
      });
    };

    handleMutation(collectionData);
  }

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>Create Collection | Bokkah</title>
        <meta name='description' content='Create a new collection' />
      </Helmet>
      <Section title="Create Your Collection" wrapperClasses='max-w-3xl m-auto' hasIcons={false}>
        <CollectionForm onSubmit={handleSubmit}>
          {isPending && 'Submitting...'}
          {!isPending && (
            <div className='flex gap-4 justify-start'>
              <Button type='submit' isPrimary disabled={isSubmitting}>
                Create Collection
              </Button>
              <Button onClick={() => navigate(-1)} className='px-4 py-2'>
                Cancel
              </Button>
            </div>
          )}
        </CollectionForm>

        {errorMessage && (
          <ErrorBlock
            title='Failed to create collection'
            message={errorMessage || 'Failed to create collection. Please check your inputs and try again later.'}
            onClose={() => setErrorMessage(null)}
          />
        )}

        {/* Redirect to the newly created collection */}
        {collectionId && navigate(`/collections/${collectionId}`)}
      </Section>
    </>
  );
}
