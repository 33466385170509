import React, { useState, FormEvent } from 'react';
import { supabase } from '../util/supabaseClient';

import Section from '../components/UI/Section';
import ContentBox from '../components/UI/ContentBox';
import Button from '../components/UI/Button';
import ErrorBlock from '../components/UI/ErrorBlock';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

export default function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const labelClasses = 'block text-gray-800 font-bold mb-4 cursor-pointer';
  const inputClasses =
    'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-4 outline-none transition-all';

  const handleNewPasswordSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!newPassword || !confirmPassword) {
      setError('Please enter both password fields.');
      return;
    }
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const { error: updateError } = await supabase.auth.updateUser({
        password: newPassword,
      });
      if (updateError) {
        throw new Error(updateError.message);
      }

      setSuccess(true);
      setError(null);
    } catch (err: any) {
      console.error(err);
      setError(err.message || 'An error occurred while updating your password.');
    }
  };

  return (
    <Section
      isNarrow
      title="Set Your New Password"
      hasIcons={false}
      wrapperClasses="max-w-lg m-auto"
      hasRecipes={false}
      hasFavoriteIcon={false}
    >
      <ContentBox>
        <div className="flex flex-col">
          {success ? (
            <div className="flex gap-4 justify-center items-center">
              <div className="flex flex-col gap-4 p-4 bg-lime-50 -m-6 rounded-md justify-center">
                <CheckCircleIcon className="mt-4 w-16 h-16 text-lime-700 m-auto" />
                <div className="flex flex-col gap-4 justify-center items-center mb-2">
                  <p className="text-center text-pretty px-4 sm:px-8 lg:px-12">
                    Your password has been updated successfully.
                  </p>
                  <Button to="/login" isPrimary={true}>
                    Go to Login
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <form onSubmit={handleNewPasswordSubmit}>
              <div className="mb-8 w-full">
                <label htmlFor="new-password" className={labelClasses}>
                  New Password
                </label>
                <input
                  id="new-password"
                  name="new-password"
                  type="password"
                  className={inputClasses}
                  placeholder="Enter your new password"
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  autoFocus
                />
              </div>

              <div className="mb-8 w-full">
                <label htmlFor="confirm-password" className={labelClasses}>
                  Confirm Password
                </label>
                <input
                  id="confirm-password"
                  name="confirm-password"
                  type="password"
                  className={inputClasses}
                  placeholder="Re-enter your new password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>

              <div className="flex gap-4 items-center">
                <Button isPrimary={true} onClick={() => handleNewPasswordSubmit} type="submit">
                  Update Password
                </Button>
              </div>
            </form>
          )}
        </div>
      </ContentBox>
      {error && <ErrorBlock title="Failed to update password" message={error} onClose={() => setError(null)} />}
    </Section>
  );
}
