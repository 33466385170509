import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchDailyAIRecipe } from '../../util/http';
import { Helmet } from 'react-helmet';
import formatDate from '../../util/date';
import Modal from '../UI/Modal';
import ErrorBlock from '../UI/ErrorBlock';
import Section from '../UI/Section';
import Button from '../UI/Button';
import H2 from '../UI/H2';
import LoadingIndicator from '../UI/LoadingIndicator';
import Share from '../UI/Share';
import { PrinterIcon, ShareIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Recipe } from '../../types';
import RecipeArticle from './RecipeArticle';

export default function AIDailyRecipeDetail() {
  const params = useParams<{ recipeId: string }>();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const { data, isPending, isError, error } = useQuery<any>({
    queryKey: ['ai-daly-recipes', params.recipeId],
    queryFn: ({ signal }) => fetchDailyAIRecipe({ id: params.recipeId, signal }),
  });

  let content;

  if (isPending) {
    content = (
      <div className="mt-4">
        <LoadingIndicator />
      </div>
    );
  }

  if (isError) {
    content = <ErrorBlock title="An error occurred" message={error?.message || 'Failed to fetch daily AI recipe.'} />;
  }

  if (data) {
    const recipe: Recipe = data;

    // Share
    const websiteUrl = `https://bokkah.com/ai/daily-recipes/${data.id}`;

    content = (
      <Section
        title={recipe.title}
        wrapperClasses="max-w-4xl lg:max-w-[60rem] m-auto"
        // hasFavoriteIcon
        recipe={recipe as any}
      >
        <RecipeArticle recipe={recipe} />
        <aside className="py-12 flex justify-center gap-4 print:hidden">
          <Button isPrimary onClick={() => setIsShareModalOpen(true)} className="flex gap-2 items-center">
            <ShareIcon className="size-4" /> Share
          </Button>
          <Button onClick={() => window.print()} className="flex gap-2 items-center">
            <PrinterIcon className="size-4" /> Print
          </Button>
          {isShareModalOpen && (
            <Modal onClose={() => setIsShareModalOpen(false)}>
              <button
                onClick={() => setIsShareModalOpen(false)}
                className="p-2 -m-2 rounded-lg hover:bg-white absolute right-4 top-4 focus:outline-none focus:shadow-outline focus:ring-4 ring-gray-300 transition-all"
              >
                <XMarkIcon className="size-4 text-gray-600" />
              </button>
              <H2 title="Share" className="text-center mb-4" />
              <Share url={websiteUrl} title={data.title} image={data.image} />
            </Modal>
          )}
        </aside>
      </Section>
    );
  }

  return (
    <>
      {data && (
        // @ts-ignore
        <Helmet>
          <title>{`"${data?.title}" by ${data?.author?.username}`}</title>
          <meta property="og:title" content={`"${data?.title}" by ${data?.author?.username}`} />
          <meta property="og:image" content={data?.image} />
          <meta
            property="og:description"
            content={`Recipe added in "${data?.category?.name}" on ${formatDate(data?.created_at)}.`}
          />
        </Helmet>
      )}
      {content}
    </>
  );
}
