import React, { useEffect, useState } from 'react';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';

export default function ErrorBlock({
  title,
  message,
  onClose,
}: {
  title: string;
  message?: string | null;
  onClose?: () => void;
}) {
  const [progress, setProgress] = useState(0);
  const [fadeIn, setFadeIn] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    // Trigger fade-in animation
    setTimeout(() => {
      setFadeIn(true);
    }, 50);

    // Increment progress every 60ms for a smooth transition
    const interval = setInterval(() => {
      setProgress((prev) => Math.min(prev + 1, 100)); // Cap at 100%
    }, 90);

    // Start fade-out animation 0.5 seconds before unmounting
    const fadeOutTimeout = setTimeout(() => {
      setFadeOut(true);
    }, 8500);

    // Handle component unmount or visibility toggle
    const timeout = setTimeout(() => {
      if (onClose) {
        onClose(); // Call onClose if provided
      } else {
        setFadeOut(false); // Reset for self-contained usage
      }
    }, 9000);

    return () => {
      clearInterval(interval);
      clearTimeout(fadeOutTimeout);
      clearTimeout(timeout);
      setFadeOut(false);
    };
  }, [onClose]);

  if (fadeOut && !onClose) return null; // Don't render if animation ends (self-contained case)

  return (
    <div
      className={`fixed top-24 sm:top-12 z-50 w-[90%] lg:w-auto max-w-[32rem] px-2 py-6 rounded-lg shadow-xl bg-rose-100 left-1/2 transform -translate-x-1/2 transition-all duration-300 ease-out ${
        fadeIn ? 'opacity-100 scale-100' : 'opacity-0 scale-105 -translate-y-full'
      } ${fadeOut ? 'opacity-5 scale-90 -translate-y-1/8' : ''}`}
    >
      <div className="flex flex-col sm:flex-row items-center text-center sm:text-start gap-4 sm:gap-6 px-6 sm:pr-12 pb-8 sm:pb-4 pt-2">
        <ExclamationTriangleIcon className="size-16 mt-2 flex text-rose-500 shrink-0" />
        <div>
          <h2 className="font-bold text-lg mb-2">{title}</h2>
          <p className="text-pretty">{message}</p>
        </div>
        <button
          onClick={() => {
            setFadeOut(true); // Start fade-out animation
            setTimeout(() => {
              if (onClose) {
                onClose(); // Call the parent-provided onClose handler
              }
            }, 180); // Match fade-out duration before removing the component
          }}
          className="p-2 -m-2 rounded bg-rose-50 hover:bg-white absolute right-3 top-3 focus:outline-none focus:shadow-outline focus:ring-4 ring-rose-300 transition-all"
          autoFocus
        >
          <XMarkIcon className="size-4 text-rose-600" />
        </button>
      </div>

      {/* Progress bar */}
      <div className="relative mt-4 -mb-4 h-2 w-full bg-rose-200 rounded-full overflow-hidden">
        <div className="absolute h-full bg-rose-500 transition-all" style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
}
