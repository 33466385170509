import React from 'react';
import { NavLink } from 'react-router-dom';
import { User } from '../../types';
import {MapPinIcon} from "@heroicons/react/24/outline";

export default function UserCard({ user }: { user: User }) {
  return (
    user && (
      <div key={user.uid} className="p-4 px-8 bg-white rounded-lg shadow-lg hover:shadow-xl transition mt-32">
        <NavLink to={`/users/${user.username}/`} className="flex flex-col items-center gap-3 transition outline-none focus:ring-4 rounded ring-offset-8">
          <img
            src={user.image ?? '/logo192.png'}
            alt={`${user.name ?? user.username}'s avatar`}
            className="bg-skeleton rounded-full size-36 relative -top-32 -mb-32 border-white border-4 bg-gray-200 shadow-lg"
            loading="lazy"
          />
          <h2 className="font-bold mt-2 -mb-2 text-gray-700">{user.name ?? user.username}</h2>
          <h3 className='text-xs tracking-wide text-gray-600 flex items-center gap-2'>
            <MapPinIcon className="size-4 text-gray-500" /> {user.country}
          </h3>
        </NavLink>
      </div>
    )
  );
}
